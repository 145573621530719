class UpdateSettingsInfo{
    motto: string;
    email: string;
    follow: number;
    friends: number;
    rooms: number;
    alerts: number;
    trades: number;

    constructor(motto: string, email: string, follow: number, friends: number, rooms: number, alerts: number, trade: number){
        this.motto = motto;
        this.email = email;
        this.follow = follow;
        this.friends = friends;
        this.rooms = rooms;
        this.alerts = alerts;
        this.trades = trade;
    }
}

export class UpdateSettingsComposer{
    header: string;
    data: UpdateSettingsInfo;

    constructor(motto: string, email: string, follow: number, friends: number, rooms: number, alerts: number, trade: number){
        this.header = "changeSettings";
        this.data = new UpdateSettingsInfo(motto, email, follow, friends, rooms, alerts, trade);
    }
}