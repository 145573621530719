import React, { useEffect, useState } from 'react'

import "./Catalog.scss";
import { BATTLEPASS_RARE, BONZEN_RARE, CASH_RARE, CASINO_RARE, CATEGORY_CRACKABLE, CATEGORY_CUSTOM, CATEGORY_LTD, CATEGORY_RARE, EVENTPUNKTE_RARE, GIVEAWAY_RARE, SCHWARZMARKT_RARE, SONSTIGE_RARE, WOCHENRARE } from './Category';
import CatalogItemView from './views/CatalogItemView';
import useWebSocket from 'react-use-websocket';
import { NotificationManager } from 'react-notifications';

const CatalogView = (props: any) => {

    const [items, setItems] = useState<any[]>([]);
    const [category, setCategory] = useState(CATEGORY_RARE);
    const [search, setSearch] = useState("");

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "updateCatalogPriceResponse" && message.data !== null) {
                const status: string = message.data.data;

                if (status === "Ok") {
                    NotificationManager.success("Aktualisierter Preis", "Ok");
                    fetchData();
                }
                else if (status === "Error") NotificationManager.error("Fehler beim Aktualisieren des Preises", "Error");
            }
        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://youtube.habbet.it/Rare/get');
            const jsonData = await response.json();
            setItems(jsonData);
        } catch (error) { }
    };

    return (
        <div className="max-w-7xl mx-auto grid grid-cols-12 mt-5 gap-4 animate__animated animate__fadeIn animate__slower">
                <div className='col-span-12 gap-2 grid grid-cols-12'>
                    <div className='col-span-12 md:col-span-8'>
                        <input className='jeInput' type="text" placeholder='Cerca raro...' onChange={(e) => setSearch(e.target.value)} value={search} />
                    </div>
                    <div className='col-span-6 md:col-span-4 h-full'>
                        <button className='bg-primary p-2 h-full hover:bg-secondary transition-all w-full rounded text-white' onClick={() => setCategory(CATEGORY_LTD)}>LTD</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(CATEGORY_RARE)}>CUSTOM</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(CATEGORY_CUSTOM)}>CUSTOM</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(CATEGORY_CRACKABLE)}>CRACKABLES</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(SCHWARZMARKT_RARE)}>MERCATO NERO RARO</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(CASINO_RARE)}>CASINO RARI</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(GIVEAWAY_RARE)}>GIVEAWAY RARI</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(WOCHENRARE)}>RARA SETTIMANALE</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(EVENTPUNKTE_RARE)}>HBT PUNTI</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(BATTLEPASS_RARE)}>BATTLEPASS RARI</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(SONSTIGE_RARE)}>ALTRI RARI</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(BONZEN_RARE)}>GRANDE RARO</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-primary p-2 hover:bg-secondary transition-all w-full rounded text-white truncate' onClick={() => setCategory(CASH_RARE)}>CONTANTI RARO</button>
                    </div>


                </div>
            <div className='row mt-4'>
                {items.length != 0 && search.length == 0 ?
                    items.filter(item => item.category === category).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
                    :
                    items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
                }
            </div>
        </div>
    )
}

export default CatalogView