import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import Draggable from 'react-draggable';
import "./Profile.scss";
import { getPreviewImage, getImagerForProfile, timeSince, getBadgeUrl, getImagerForTops, getImagerForTopsProfile, LocalizeText } from '../../Globals';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Sticker from './Sticker';
import Widget from './Widget';
import Note from './Note';

import { NotificationManager } from 'react-notifications';

import { GetProfileComposer } from '../../packets/GetProfileComposer';
import { UpdateProfileComposer } from '../../packets/UpdateProfileComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';

import LoadingView from '../loading/LoadingView';



const ProfileView = (props, { match }) => {
    const [background, setBackground] = useState("https://files.habboemotion.com/resources/images/website_backgrounds/bg_blue.gif");
    const [stickers, setStickers] = useState<any[]>([]);
    const [widgets, setWidgets] = useState<any[]>([]);
    const [notes, setNotes] = useState<any[]>([]);
    const [disabled, setDisabled] = useState(true);
    const [noteContent, setNoteContent] = useState("");
    const [loading, setLoading] = useState(true);

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const navigate = useNavigate();
    const params = useParams();

    const [account, setAccount] = useState<any>(null);
    const [badges, setBadges] = useState<any>(null);
    const [groups, setGroups] = useState<any>(null);
    const [friends, setFriends] = useState<any>(null);
    const [lastOnline, setLastOnline] = useState<any>(null);
    const [lastRegister, setLastRegister] = useState<any>(null);
    const [rooms, setRooms] = useState<any>(null);
    const [tweets, setTweets] = useState<any>(null);


    // lariesse

    const [modalOpen, setModalOpen] = useState(false);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        if (modalContentRef.current && modalContentRef.current.contains(e.target as Node)) {
            return;
        }

        setModalOpen(false);
    };


    const [activeTab, setActiveTab] = useState('background');
    const [activeSubTab, setActiveSubTab] = useState('kleinen');
  
    const handleTabClick = (tab) => {
      setActiveTab(tab);
      setActiveSubTab('kleinen'); 
    };
  
    const handleSubTabClick = (subTab) => {
      setActiveSubTab(subTab);
    };

    const stickerContents = {
        Piccoli: "Piccoli",
        Medio: "Medio",
        Grande: "Grande",
        Lettere: "Lettere",
        Campane: "Campane",
      };
    
    //

    const getParamName = (param) => {
        if (param === null) return "no";
        else return param;
    }

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        if (params === null || params.name === null) navigate("/me", { replace: true })
        sendMessage(JSON.stringify(new GetProfileComposer(getParamName(params.name))))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "profileData" && message.data !== null) {
                if (message.data.data !== null && message.data.data === "Este usuario no existe") navigate("/me", { replace: true })
                else {
                    setAccount(JSON.parse(message.data.account));
                    setBadges(JSON.parse(message.data.badges));
                    setGroups(JSON.parse(message.data.groups));
                    setFriends(JSON.parse(message.data.friends));
                    setLastOnline(message.data.lastOnline);
                    setLastRegister(message.data.lastRegister);
                    setRooms(JSON.parse(message.data.rooms));
                    setTweets(JSON.parse(message.data.tweets));

                    if (message.data.style !== null) {
                        const style = JSON.parse(message.data.style);
                        setBackground(style.background);
                        setWidgets(style.widgets);
                        setNotes(style.notes);
                        setStickers(style.stickers);
                    }
                    setLoading(false);
                }
            }

        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        if (props !== null && props.account !== null && account !== null && account.id === props.account.id) setDisabled(false);
    }, [account])

    useEffect(() => {
        if (props !== null && props.account !== null && account !== null && account.id === props.account.id) sendMessage(JSON.stringify(new UpdateProfileComposer(background, stickers, widgets, notes)));
    }, [background, stickers, widgets, notes])

    const changeBackground = (bg) => setBackground(bg)

    const setSticker = (sticker) => {
        if (stickers.length > 300) {
            NotificationManager.error(LocalizeText(props.texts, 'profile.length.stickers'));
            return;
        }
        setStickers([...stickers, new Sticker(Math.random(), sticker, 0, 0)])
    }

    const setWidget = (widget) => {
        if (widgets.filter(obj => obj.type === widget).length !== 0) {
            NotificationManager.error(LocalizeText(props.texts, 'profile.length.widgets'), "Error");
            return;
        }
        setWidgets([...widgets, new Widget(Math.random(), widget, 0, 0)])
    }

    const setNote = () => {
        LocalizeText(props.texts, '')
        if (noteContent === "") {
            NotificationManager.error(LocalizeText(props.texts, 'profile.note.empty'), "Error");
            return;
        }

        if (noteContent.length > 1000) {
            NotificationManager.error(LocalizeText(props.texts, 'profile.note.empty'), "Error")
            return;
        }

        if (notes.length > 10) {
            NotificationManager.error(LocalizeText(props.texts, 'profile.length.notes'), "Error");
            return;
        }

        setNotes([...notes, new Note(Math.random(), noteContent, 0, 0)])
        setNoteContent("");
    }

    const updateStickerPos = (id, x, y) => {
        const newState = stickers.map(obj => {
            if (obj.id === id) {
                return { ...obj, x: x, y: y };
            }

            return obj;
        });

        setStickers(newState);
    }

    const updateWidgetPos = (id, x, y) => {
        const newState = widgets.map(obj => {
            if (obj.id === id) {
                return { ...obj, x: x, y: y };
            }

            return obj;
        });

        setWidgets(newState);
    }

    const updateNotePos = (id, x, y) => {
        const newState = notes.map(obj => {
            if (obj.id === id) {
                return { ...obj, x: x, y: y };
            }

            return obj;
        });

        setNotes(newState);
    }

    const deleteSticker = (id) => {
        setStickers(stickers.filter(item => item.id !== id));
    }

    const deleteNote = (id) => {
        setNotes(notes.filter(item => item.id !== id));
    }

    const deleteWidget = (id) => {
        setWidgets(widgets.filter(item => item.id !== id));
    }

    const getBackgroundConfig = (bg) => {
        return (
            <div className="col-span-5 md:col-span-1">
                <div onClick={() => changeBackground(bg)} style={{ background: getPreviewImage(bg) }} className="me-background-preview w-full"></div>
            </div>
        )
    }

    const getStickerBigConfig = (stick) => {
        return (
            <div className="col-span-4 md:col-span-1">
                <div data-bs-dismiss="modal" onClick={() => setSticker(stick)} style={{ background: getPreviewImage(stick), backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "147px" }} className="sticker-preview w-100"></div>
            </div>
        )
    }

    const getStickerMediumConfig = (stick) => {
        return (
            <div className="col-span-5 md:col-span-1">
                <div data-bs-dismiss="modal" onClick={() => setSticker(stick)} style={{ background: getPreviewImage(stick), backgroundPosition: "center", backgroundRepeat: "no-repeat" }} className="sticker-preview w-100"></div>
            </div>
        )
    }

    const getStickerSmallConfig = (stick) => {
        return (
            <div className="col-span-5 md:col-span-1">
                <div data-bs-dismiss="modal" onClick={() => setSticker(stick)} style={{ background: getPreviewImage(stick), backgroundPosition: "center", backgroundRepeat: "no-repeat" }} className="sticker-preview w-100"></div>
            </div>
        )
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const getGroupBadgeUrl = (badge) => "https://swfs.habbet.it/c_images/Badgeparts/generated/" + badge + ".png";

    const profileLink = (name) => {
        setLoading(true);
        sendMessage(JSON.stringify(new GetProfileComposer(name)))
        navigate("/profile/" + name, { replace: true })
    }

    const deleteTweet = (id) => {
        sendMessage(JSON.stringify(new DeleteTweetComposer(id)))
        profileLink(account.username);
    }

    const ProfileBoard = () => {
        return (
            <div className='profile-main  mt-5  profile-board' style={{ background: getPreviewImage(background) }}>
                {stickers.map((sticker) =>
                    <Draggable disabled={disabled} defaultPosition={{ x: sticker.x, y: sticker.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateStickerPos(sticker.id, d.x, d.y)}>
                        <div className='s-stick'>
                            <img src={sticker.url} />
                            {disabled && props.account !== null && props.account.id === account.id &&
                                <>
                                    <br />
                                    <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteSticker(sticker.id)}>X</div>
                                </>
                            }
                        </div>
                    </Draggable>
                )}
                {widgets.map((widget) =>
                    <>
                        {widget.type === "profile" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-widget-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title7')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-3 p-5'>
                                            <div className='col-span-6'>
                                                <span className='badge bg-dark profile-username-badge'>{account.username}</span><br />
                                                <span className='badge'>Motto: {account.motto}</span><br />
                                                <span className='badge bg-warning'>{account.currency} {LocalizeText(props.texts, 'global.currency')}</span><br />
                                                <span className='badge'>{account.diamonds} {LocalizeText(props.texts, 'global.diamonds')}</span>
                                            </div>
                                            <div className='col-span-6'>
                                                <img src={getImagerForProfile(account.look)} className="profile-username-img f-right" />
                                            </div>
                                        <hr className='col-span-12' style={{ border: "1px solid #fff" }} />
                                            <div className='col-span-6' style={{ marginTop: "-4px" }}>
                                                <span className='badge bg-dark'>{LocalizeText(props.texts, 'profile.last.register')} {formatDate(new Date(lastRegister * 1000))}</span>
                                            </div>
                                            <div className='col-span-6'>
                                                <span className='badge f-right'>{LocalizeText(props.texts, 'profile.last.date')} {timeSince(lastOnline)}</span>
                                            </div>
                                    </div>
                                </div>
                            </Draggable>
                        }
                        {widget.type === "badges" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-badge-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title6')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='card-body profile-widget-body'>
                                        <div className='grid grid-cols-12 p-2 gap-2 max-h-[260px] overflow-y-auto'>
                                            {badges === null || badges.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.badges')}</div></div> : (
                                                <>
                                                    {badges.map((badge) =>
                                                        <div className='col-span-3'>
                                                            <div style={{ background: getPreviewImage(getBadgeUrl(badge)), backgroundPosition: "center", backgroundRepeat: "no-repeat" }} className="w-100 profile-badge-img"></div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {disabled && props.account !== null && props.account.id === account.id &&
                                        <>
                                            <br />
                                            <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteWidget(widget.id)}>X</div>
                                        </>
                                    }
                                </div>
                            </Draggable>
                        }
                        {widget.type === "groups" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-badge-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title5')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='card-body p-2 profile-widget-body'>
                                        <div className='row'>
                                            {groups === null || groups.length === 0 ? <div className='col-md-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.groups')}</div></div> : (
                                                <>
                                                    {groups.map((group) =>
                                                        <>
                                                            {group.badge !== null && group.badge !== "" &&
                                                                <div className='col-md-12 mb-3'>
                                                                    <div className='row'>
                                                                        <div className='col-md-2'>
                                                                            <img src={getGroupBadgeUrl(group.badge)} />
                                                                        </div>
                                                                        <div className='col-md-10'>
                                                                            <div className="d-flex justify-content-between" style={{ marginTop: "-3px" }}>
                                                                                <div>
                                                                                    {group.name}
                                                                                </div>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginTop: "-4px" }}>
                                                                                <small>
                                                                                    <small>
                                                                                        {group.description}
                                                                                    </small>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {disabled && props.account !== null && props.account.id === account.id &&
                                        <>
                                            <br />
                                            <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteWidget(widget.id)}>X</div>
                                        </>
                                    }
                                </div>
                            </Draggable>
                        }
                        {widget.type === "friends" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-badge-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title4')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='card-body profile-widget-body max-h-[260px] overflow-y-auto'>
                                            {friends === null || friends.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.friends')}</div></div> : (
                                                <>
                                                    {friends.map((friend) =>
                                                        <>
                                                            <div className='flex gap-2 items-center'>
                                                                    <div className='min-w-[64px] max-w-[64px] max-h-[64px] min-h-[64px]'>
                                                                        <div className="icon-perfiles-content h-[110px]" style={{ backgroundImage: getImagerForTopsProfile(friend.look) }}></div>
                                                                    </div>
                                                                    <div className='w-full h-full mt-3'>
                                                                            <span className='badge bg-dark text-white' style={{ fontSize: "16px", fontWeight: "bold" }}>{friend.username}</span> &nbsp;
                                                                            <button onClick={() => profileLink(friend.username)} className='badge bg-secondary p-2 rounded text-white me-pointer' style={{ fontSize: "16px", fontWeight: "bold" }}>Al Profilo</button>
                                                                    </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                    {disabled && props.account !== null && props.account.id === account.id &&
                                        <>
                                            <br />
                                            <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteWidget(widget.id)}>X</div>
                                        </>
                                    }
                                </div>
                            </Draggable>
                        }
                        {widget.type === "rooms" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-badge-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title3')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='card-body profile-widget-body max-h-[260px] overflow-y-auto'>
                                            {rooms === null || rooms.length === 0 ? <div className='col-md-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.rooms')}</div></div> : (
                                                <>
                                                    {rooms.map((room) =>
                                                        <>
                                                            <div className='flex p-2 gap-2 items-center'>
                                                                        <div className="min-w-[31px] icon-room-content" style={{ backgroundImage: "url(https://images.hartico.com/cosmos/img/icons/rooms/room_icon_2.gif)" }}></div>
                                                                    <div className='w-full'>
                                                                        
                                                                            <span className='badge bg-dark text-white' style={{ fontSize: "16px", fontWeight: "bold" }}>{room.roomName}</span> &nbsp;
                                                                    </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                    {disabled && props.account !== null && props.account.id === account.id &&
                                        <>
                                            <br />
                                            <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteWidget(widget.id)}>X</div>
                                        </>
                                    }
                                </div>
                            </Draggable>
                        }
                        {widget.type === "tweets" &&
                            <Draggable disabled={disabled} defaultPosition={{ x: widget.x, y: widget.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateWidgetPos(widget.id, d.x, d.y)}>
                                <div className='card border-0 profile-badge-card'>
                                    <div className="p-2 bg-primary">
                                        <div className="align-left">
                                            {LocalizeText(props.texts, 'profile.head.title2')} {account.username.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className='card-body profile-widget-body'>
                                        <div className='row'>
                                            {tweets === null || tweets.length === 0 ? <div className='col-md-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.tweets')}</div></div> : (
                                                <>
                                                    {tweets.map((tweet) =>
                                                        <>
                                                            <div className='col-md-12 mb-1'>
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <div className="icon-perfiles-content" style={{ backgroundImage: getImagerForTops(account.look) }}></div>
                                                                        <span className='badge bg-secondary text-white pre-line'>Hace {timeSince(tweet.date)}</span>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <div style={{ marginTop: "40px" }}>
                                                                            <span className='badge bg-dark text-white pre-line'>{tweet.tweetText}</span><br />
                                                                            {disabled && props.account !== null && props.account.id === account.id && <span className='badge bg-danger text-white me-pointer' onClick={() => deleteTweet(tweet.id)}>Borrar</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {disabled && props.account !== null && props.account.id === account.id &&
                                        <>
                                            <br />
                                            <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteWidget(widget.id)}>X</div>
                                        </>
                                    }
                                </div>
                            </Draggable>
                        }
                    </>
                )}
                {notes.map((note) =>
                    <Draggable disabled={disabled} defaultPosition={{ x: note.x, y: note.y }} bounds="parent" key={Math.random()} onStop={(e, d) => updateNotePos(note.id, d.x, d.y)}>
                        <div className='card border-0 profile-widget-card text-white'>
                            <div className='p-2 card-body pre-line'>
                                {note.text}
                            </div>
                            {disabled && props.account !== null && props.account.id === account.id &&
                                <>
                                    <br />
                                    <div className='btn btn-danger danger-habbo btn-sm profile-delete-button w-100' onClick={() => deleteNote(note.id)}>X</div>
                                </>
                            }
                        </div>
                    </Draggable>
                )}
            </div>
        )
    }

    const ProfileBoardCached = useMemo(() => <ProfileBoard />, [background, stickers, widgets, notes, disabled])

    return (
        <>
            {loading ? <LoadingView /> : (
                <>
                    <div className='animate__animated gap-2 max-w-7xl w-full grid grid-cols-12 mx-auto animate__fadeIn animate__slower'>
                        {props.account !== null && props.account.id === account.id &&
                            <div className='col-span-12 grid grid-cols-2 w-full gap-2 max-w-[1116px] mx-auto'>
                                <div className='col-span-2 md:col-span-1'>
                                    <button onClick={openModal} className="btn p-2 rounded btn-kubbo-success w-full mt-3" data-bs-toggle="modal" data-bs-target="#settingsProfileModal">{LocalizeText(props.texts, 'profile.add')}</button>
                                </div>
                                <div className='col-span-2 md:col-span-1'>
                                    <button className='btn p-2 rounded btn-kubbo-primary w-full mt-3' onClick={() => setDisabled(!disabled)}>
                                        {disabled ? <>{LocalizeText(props.texts, 'profile.edit')}</> : <>{LocalizeText(props.texts, 'profile.delete')}</>}
                                    </button>
                                </div>
                            </div>
                        }
                        <div className='col-span-12 mt-5' style={{ overflowX: "auto" }}>
                            {ProfileBoardCached}
                        </div>
                    </div>
                    {modalOpen && (
                        <div className="modal-overlay flex  justify-center items-center fixed top-0 left-0 bottom-0 bg-secondary w-full h-full bg-opacity-50 backdrop-blur-[2px]" onClick={closeModal}>
                            <div className="modal-content animate__animated animate__fadeInDown bg-primary max-w-2xl rounded p-2 w-full flex flex-col" ref={modalContentRef}>
                                <div className="modalHeader flex justify-between p-3 bg-secondary rounded">
                                    <p className='font-bold'>{LocalizeText(props.texts, 'profile.head.title')}</p>
                                    <button onClick={() => setModalOpen(false)}>X</button>
                                </div>
                                <div className="grid grid-cols-4 p-2 gap-2">
                                    <button onClick={() => handleTabClick('background')} className="col-span-2 bg-[#101010] hover:bg-gray-800 transition-all duration-500 p-2 rounded text-center md:col-span-1">
                                        {LocalizeText(props.texts, 'profile.background')}
                                    </button>
                                    <button onClick={() => handleTabClick('stickers')} className="col-span-2 bg-[#101010]  hover:bg-gray-800 transition-all duration-500 p-2 rounded text-center md:col-span-1">
                                        {LocalizeText(props.texts, 'profile.stickers')}
                                    </button>
                                    <button onClick={() => handleTabClick('widgets')} className="col-span-2 bg-[#101010] hover:bg-gray-800 transition-all duration-500 p-2 rounded text-center md:col-span-1">
                                        {LocalizeText(props.texts, 'profile.widgets')}
                                    </button>
                                    <button onClick={() => handleTabClick('notes')} className="col-span-2 bg-[#101010] hover:bg-gray-800 transition-all duration-500 p-2 rounded text-center md:col-span-1">
                                        {LocalizeText(props.texts, 'profile.notes')}
                                    </button>
                                </div>
                                <div className="tab-content max-h-80 h-full overflow-y-auto">
                                    {activeTab === 'background' && 
                                    <div className='grid grid-cols-4 gap-3'>
{getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/bg_pattern_space.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/bg_pattern_tinyroom.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/lace.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/HC_royal.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/ruled_paper.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/bg_pattern_fish.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/bg_bobbaheart.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/bg_serpentine_darkred.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/tonga.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/nl_green_bg.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/xmas_bgpattern_starsky.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/CheeseWedge_wallpaper.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/homebgs/metal2.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/05.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/30.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/29.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/streets_background3.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/val_bg.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/hp_back13.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/hp_back17.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/15.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/10.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/snowbg1.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/39.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/27.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/newbg.png")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_petal.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/hp_back4.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/streets_background2.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/hp_back9.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_empty.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/42.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/stripes.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_pattern_cars.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_tiles.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_newh.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/ham_back.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bubble.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/02.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/24.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_newh2.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/17.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_pink.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/14.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/bg_frog.gif")}
                                                {getBackgroundConfig("https://files.habboemotion.com/resources/images/website_backgrounds/06.gif")}
                                    </div>}
                                    {activeTab === 'stickers' && (
          <div className="stickers-content">
            <div className="sub-tabs px-3 grid grid-cols-5 gap-2">
              {Object.keys(stickerContents).map((subTab) => (
                <button
                  key={subTab}
                  className={activeSubTab === subTab ? 'active col-span-5 p-2 rounded hover:bg-gray-900 md:col-span-1 bg-secondary' : 'col-span-5 rounded hover:bg-gray-900 md:col-span-1 bg-[#101010]'}
                  onClick={() => handleSubTabClick(subTab)}
                >
                  {subTab.charAt(0).toUpperCase() + subTab.slice(1)}
                </button>
              ))}
            </div>
            <div className="sub-tab-content grid grid-cols-5">
              <div className='col-span-5'>
                {activeSubTab === 'Piccoli' && (
                  <div  className='col-span-5 grid grid-cols-5'>
 {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/scubacapsule_anim.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/bullet1.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_eye_anim.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/parrot.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/needle_1.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/paper_clip_1.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_glasses_supernerd.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_flower_pink.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_eye_evil_anim.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/swimming_fish.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/needle_2.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_glasses_elton.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/lightbulb.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_catinabox.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/needle_3.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_spaceduck.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/needle_4.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_eyes_blue.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/battle3.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/needle_5.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/paper_clip_3.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_arrow_down.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/battle1.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_arrow_up.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/fish.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_flames.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_arrow_left.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/football.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/sticker_arrow_right.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/general/small/paper_clip_2.gif")}
                  </div>
                )}
                {activeSubTab === 'Medio' && (
                  <div className='col-span-5 grid grid-cols-4 mt-1'>
{getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/nail2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_croco.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/spill3.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/finger_push.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/bear.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/zombiepupu.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_clown_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_gurubeard_grey.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_flower1.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/eraser.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_cactus_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_gentleman.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/pencil.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/spill1.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/burger.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/plaster2.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/ruler_horizontal.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/nail3.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/chewed_bubblegum.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_boxer.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_bobbaskull.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/i_love_coffee.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_submarine.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/i_love_bobba2.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_hole.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/spill2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/ruler_vertical.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/plaster.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_sleeping_habbo.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/juice.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/pencil_2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_flower_big_yellow.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/general/medium/sticker_gurubeard_brown.gif")}
                  </div>
                )}
                {activeSubTab === 'Grande' && (
                  <div className='col-span-5 grid grid-cols-3 mt-1'>
{getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/leafs2.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_coffee_steam_grey.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_v_end.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/gorillahand2.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_coffee_steam_blue.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_v_tile.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/gorillahand1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_h_bobba_lock.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_1.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/gorillahand1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/leafs1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/vine2.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_mark1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_mark6.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_pointing_hand_2.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_2.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_h_tile.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_effect_whoosh2.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_h_normal_lock.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_pointing_hand_4.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/cassette3.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_v_bobba_lock.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/cassette2.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/vine1.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/bling.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/redlamp.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/wunderfrank.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_mark3.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/chain_vertical.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/chain_horizontal.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_effect_burp.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_effect_bam.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/cassette4.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_mark5.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/highlighter_mark4b.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_h_end.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_coffee_stain.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_pointing_hand_3.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_effect_woosh.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_pointing_hand_1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/blingblingstars.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_zipper_v_normal_lock.gif")}

                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/cassette1.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_moonpig.gif")}
                                                        {getStickerBigConfig("https://www.habborator.org/archive/stickers/general/large/sticker_effect_zap.gif")}
                  </div>
                )}
                {activeSubTab === 'Lettere' && (
                  <div className='col-span-5 grid grid-cols-5 md:grid-cols-6'>
                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_a.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_b.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_c.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_d.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_e.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_f.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_g.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_h.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_i.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_j.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_k.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_l.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_m.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_n.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_o.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_p.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_q.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_r.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_s.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_t.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_u.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_v.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_w.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_x.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_y.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_z.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_question.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_exclamation.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_comma.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_underscore.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_star.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_1/bling_dot.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/a.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/b.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/c.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/d.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/e.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/f.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/g.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/h.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/i.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/j.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/k.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/l.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/m.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/n.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/o.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/p.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/q.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/r.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/s.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/t.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/u.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/v.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/w.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/x.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/y.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/z.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/acsent1.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/exclamation.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/acsent2.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/underscore.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/star.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_2/dot.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_a.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_b.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_c.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_d.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_e.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_f.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_g.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_h.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_i.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_j.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_k.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_l.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_m.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_n.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_o.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_p.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_q.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_r.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_s.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_t.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_u.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_v.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_w.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_x.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_y.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_z.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_acsent.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_exclamation.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_acsent2.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_undermark.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_comma.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_3/wood_dot.gif")}

                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_a.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_b.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_c.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_d.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_e.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_f.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_g.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_h.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_i.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_j.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_k.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_l.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_m.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_n.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_o.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_p.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_q.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_r.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_s.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_t.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_u.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_v.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_w.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_x.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_y.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_z.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_hyphen.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_exclamation.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_period.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_question.gif")}
                                                        {getStickerSmallConfig("https://www.habborator.org/archive/stickers/alphabet_10/sticker_sign_comma.gif")}
                  </div>
                )}
                {activeSubTab === 'Campane' && (
                  <div className='col-span-5 grid grid-cols-4 mt-1'>
                    {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_dragon_body_l.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_kungfu_dude.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_dragon_body_r.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_dragon_head_r.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/newyear_2007_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_kungfu_girl.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_hypnodragon_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/newyear/cny_dragon_head_l.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/Evil_giant_bunny.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/skull2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/skeletor_001.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/sticker_hw08_creepyDoll.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/wormhand.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/eyeright.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/sticker_hw08_skullFrown.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/eyeleft.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/sticker_hw08_evilPumpkin.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/halloween/skull.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_heavy.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_bling.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_disco.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_reggae.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_sfx.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_rock.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_electro.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_8_bit.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_ambient.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/trax/trax_latin.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_birdsuit.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/grass.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_broomstick_001.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf6.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf1.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_pointyhat.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_bunnymoped.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_rabbit_in_hole.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_iovetree.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_eggs_vertical_001.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_carrot_rocket.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf5.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf8.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_bird.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf4.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf3.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easter_eggs_horizontal.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/easteregg_costume.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/easter/stick_easter_leaf7.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_skateboard.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_swim_trunk.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_rowingboat.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_flowerwreath.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/sunflower.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/barbequeset.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_rollerblades.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_blueberry_right.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_beachballafro.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summer_cloud2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/summersticker_strawberry.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/summer/clothesline.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/gothic/gothic_draculacape.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/snowball_bumpy.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_costume_blue.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_costume_red.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/lapanen_red.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/icecube_big.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/lapanen_purple.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_snowflake1_001.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/extra_ss_duck_right.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_bootsitjalapaset_red.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/snowball.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_snowtree.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/extra_ss_snowball.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/extra_ss_duck_left.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/lapanen_blue.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_snowman.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/snowball_smooth.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_bootsitjalapaset_blue.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_snowballmachine.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_snowqueen.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/snowstorm/ss_hits_by_snowball.gif")}

                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_amp_small.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_guitarplayer_black.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_camopants_l.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_amp_big.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_camopants_r.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_bassplayer_girl.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_drummer_girl.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_bassplayer_boy.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_drummer_boy.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_guitarplayer_white.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_keyboards.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/moh/hw_microphone.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/sticker_country_donkey.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_sheep1.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_cow.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_horse.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_cow2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_sheep2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/Country/stick_country_pig.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_shamrock.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_shamBorderV.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/sticker_stPat_M.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/sticker_stPat_F.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_claddagh.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_kissMe.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/sticker_stPat_cider.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_potOGold.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/sticker_stPat_hat.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/occasional/stpatrick/patsDay_shamBorderH.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_croco.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_heartbeat.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_lovedice.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_dreamer.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_rosewire_corner.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_mamasboy.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/valentine_welcome_sticker.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_lovecostume3.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_storm_costume.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/blinghearts.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_cupido_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_lonewolf.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_skull360_circle.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_romantic.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/sticker_prankster.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_barbwire_vert.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_cupid_arrow.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_barbwire_horis.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_skull360.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_voodoo_suit.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_rosewire_horis.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/val_sticker_rosewire_vert.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/valentine/tall_ship.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_dograindeer_sticker.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_suit_mint.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_suit_pink.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_boxs.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_tree_costume.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_strap_vertical_gold.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_darkred2.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_orange.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_strap_horiz_silve.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/angelwings_anim.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_gift_strap_v.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_suit_blue.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_box_lime.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_gift_strap_corner_r.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_smilla_snowboard.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_xtree_sticker.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_santa_typical.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/xmas_tree01_animated.gif")}
                                                        {getStickerMediumConfig("https://www.habborator.org/archive/stickers/christmas/Xmas_skater_costume.gif")}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
                                    {activeTab === 'widgets' && 
                                        <div className='flex flex-col gap-2'>
                                            <button className="w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]" onClick={() => setWidget("groups")}>{LocalizeText(props.texts, 'profile.groups')}</button>
                                            <button className="w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]" onClick={() => setWidget("rooms")}>{LocalizeText(props.texts, 'profile.rooms')}</button>
                                            <button className="w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]" onClick={() => setWidget("badges")}>{LocalizeText(props.texts, 'profile.badges')}</button>
                                            <button className="w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]" onClick={() => setWidget("friends")}>{LocalizeText(props.texts, 'profile.friends')}</button>
                                            <button className="w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]" onClick={() => setWidget("tweets")}>{LocalizeText(props.texts, 'profile.tweets')}</button>
                                        </div>}
                                    {activeTab === 'notes' && 
                                        <div className='flex flex-col p-2 gap-2'>
                                            <textarea value={noteContent} onChange={(e) => setNoteContent(e.target.value)} className='jeInput' placeholder={LocalizeText(props.texts, 'profile.note.txt')}></textarea>
                                            <button onClick={() => setNote()} className='w-full text-center p-2 rounded bg-[#198754] border-1 border-[#198754] hover:bg-[#157347] hover:border-[#146c43]'>{LocalizeText(props.texts, 'profile.note.create')}</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    )}

                </>
            )}
        </>
    )
}


export default ProfileView
