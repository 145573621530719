class Data{
    id: number;
    
    constructor(id: number){
        this.id = id;
    }
}

export class ArticleComposer{
    header: string;
    data: Data;

    constructor(id: number){
        this.header = "article";
        this.data = new Data(id);
    }
}