import React, { useMemo } from 'react'
import { NavbarView } from './NavbarView'
import { BannerView } from './BannerView';

import "./Header.scss";
import { NavbarSecondaryView } from './NavbarSecondaryView';
import { BannerLoggedView } from './BannerLoggedView';

interface HeaderViewProps{
  tops: {[key: string] : string},
  community: {[key: string] : string},
  online: number,
  account: any,
  texts: any,
  logout: () => void
}

export const HeaderView = (props: HeaderViewProps) => {
  const ProfileBoardCached = useMemo(() => <BannerView online={props.online} tops={props.tops} community={props.community} />, [props.tops, props.community])

  return (
    <div className='animate__animated animate__fadeInDown'>
        <NavbarView online={props.online} texts={props.texts} logout={props.logout} account={props.account} />
        {props.account === null ? <>{ProfileBoardCached}</> : (
          <BannerLoggedView account={props.account} />
        )}
        <NavbarSecondaryView account={props.account} />
        
    </div>
  )
}
