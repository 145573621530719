import React from 'react'
import { getImagerForTops, LocalizeText } from '../../Globals'

const CommunityGroupTop = (props) => {
  
  const getBadgeUrl = () => "url(https://images.habbet.it/c_images/Badgeparts/generated/"+props.url+".png)";
  
  return (
    <div className="flex justify-between items-center hover:-translate-y-1 transition-all  bg-primary p-3 rounded icon-keko-separator">
          <div className="flex items-center gap-2">
            <div className="icon-group-content" style={{backgroundImage: getBadgeUrl()}}></div> 
            <p className='font-bold text-white'>{props.name}</p>
          </div>
          <div className="bg-secondary p-2 rounded hidden md:block">{props.memberCount} {LocalizeText(props.texts, 'community.global.groupscore')}</div>  
    </div>
  )
}

export default CommunityGroupTop
