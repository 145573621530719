import React from 'react'
import { getImagerForTops, LocalizeText } from '../../Globals'

const CommunityRoomTop = (props) => {
  
  return (
    <div className="flex justify-between items-center hover:-translate-y-1 transition-all  bg-primary p-3 rounded icon-keko-separator">
          <div className="flex items-center gap-2">
            <div className="icon-room-content" style={{backgroundImage: "url(https://images.hartico.com/cosmos/img/icons/rooms/room_icon_3.gif)"}}></div> 
            <p className='font-bold text-white'>{props.roomName}</p>
          </div>
          <div className="hidden md:flex gap-2 items-center">
              <p className='bg-secondary p-2 rounded'>{props.userCount}/{props.userMax}</p>
              <p className='bg-secondary p-2 rounded'>{LocalizeText(props.texts, 'Stanze Popolari')} {props.roomScore}</p>
          </div>  
    </div>
  )
}

export default CommunityRoomTop
