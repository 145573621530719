import React, { FC, useEffect, useState } from 'react'
import CatalogItemUserView from './CatalogItemUserView';
import useWebSocket from 'react-use-websocket';
import { UpdatePriceRareComposer } from '../../../packets/UpdatePriceRareComposer';

interface ModalCatalogItemProps {
    item: any;
    canEdit: boolean;
}

const ModalCatalogItemView: FC<ModalCatalogItemProps> = props => {
    const { item = null, canEdit = false } = props;
    const [ editVisible, setEditVisible ] = useState(false);
    const [ credits, setCredits ] = useState(0);
    const [ diamonds, setDiamonds ] = useState(0);

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    useEffect(() =>{
        setCredits(item.credits)
        setDiamonds(item.diamonds)
    }, [])

    const toggleEdit = () => {
        setEditVisible(!editVisible);
    }

    const updatePrice = () => {
        if(credits != null && diamonds != null){
            sendMessage(JSON.stringify(new UpdatePriceRareComposer(item.id, credits, diamonds)))
            toggleEdit();
        }
    }

    return (
        <div className="modal fade" id={`newRareCodeId-${item.id}`} tabIndex={-1} aria-labelledby="newRareCodeIdLabel" style={{ zIndex: "99", display: "none" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content card-shadow" style={{ border: "1px solid rgba(56, 56, 56, 0.9)" }}>
                        <div className="modal-body bg-dark text-white">
                            <div style={{ fontWeight: "bold", marginBottom: "20px; color: rgb(255, 255, 255)" }}>
                                <b>{item.name}</b>
                            </div>
                            <div className='catalog-furni-card mt-2 justify-content-center' style={{ height: "230px" }}>
                                {canEdit ?
                                    editVisible ? 
                                        <div className='row' style={{position: "absolute", zIndex: "1"}}>
                                            <div className='col-6'>
                                                <button  className="btn btn-success btn-sm" style={{position: "absolute", zIndex: "1", marginRight: "2px"}} onClick={() => updatePrice()}>Save</button>
                                            </div>
                                            <div className='col-6'>
                                                <button  className="btn btn-danger btn-sm" onClick={() => toggleEdit()}>Cancel</button>
                                            </div>
                                        </div>
                                        :
                                        <button  className="btn btn-warning btn-sm" style={{position: "absolute", zIndex: "1"}} onClick={() => toggleEdit()}>Edit</button>
                                        :
                                        <></>
                                }
                                <div className='catalog-furni-image-modal' style={{ backgroundImage: `url(${item.image})` }}/>
                            </div>
                            <>
                            {canEdit && editVisible ?
                                <div className='row gx-1'>
                                    <div className='col-md-6'>
                                        <span className='badge bg-warning text-white text-center w-100 catalog-furni-size-price-bar'>
                                            <input className='catalog-furni-price catalog-item-input_edit'  value={credits} type='number' onChange={(e) => setCredits(Number(e.target.value))}/>
                                            <img src="https://images.habbet.it/images/wallet/-1.png" />
                                        </span>
                                    </div>
                                    <div className='col-md-6'>
                                        <span className='badge bg-info text-white text-center w-100 catalog-furni-size-price-bar'>
                                            <input className='catalog-furni-price catalog-item-input_edit' value={diamonds} type='number' onChange={(e) => setDiamonds(Number(e.target.value))}/>
                                            <img src="https://images.habbet.it/images/wallet/5.png" />
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className='row gx-1'>
                                    <div className='col-md-6'>
                                        <span className='badge bg-warning text-white text-center w-100 catalog-furni-size-price-bar'>
                                            <div className='catalog-furni-price'>{item.price_credits == 1 ? "Unbekannt" : item.price_credits}</div>
                                            <img src="https://images.habbet.it/images/wallet/-1.png" />
                                        </span>
                                    </div>
                                    <div className='col-md-6'>
                                        <span className='badge bg-info text-white text-center w-100 catalog-furni-size-price-bar'>
                                            <div className='catalog-furni-price'>{item.price_diamonds == 1 ? "Unbekannt" : item.price_diamonds}</div>
                                            <img src="https://images.habbet.it/images/wallet/5.png" />
                                        </span>
                                    </div>
                                </div>
                            }
                            </>
                            
                            <div className="mb-3" style={{ maxHeight: "350px", overflow: "auto" }}>
                                <div className='catalog-users-box'>
                                    {item.users.length != 0 && item.users.map(x =>
                                        <CatalogItemUserView look={x.look} username={x.username} quantity={x.quantity} />
                                    )}
                                </div>
                            </div>
                            <div className="w-100">
                                <button className="btn btn-danger w-100" data-bs-dismiss="modal" aria-label="Close">Schließen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ModalCatalogItemView