import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const NavbarSecondaryView = (props) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="bg-primary border-gray-200">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-center mx-auto p-4">
                <button
                    onClick={toggleMenu}
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-secondary focus:outline-none focus:ring-2 focus:bg-secondary"
                    aria-controls="navbar-default"
                    aria-expanded={isMenuOpen ? "true" : "false"}>
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className={`${
                    isMenuOpen ? 'block' : 'hidden'
                    } w-full lg:block lg:w-auto`} id="navbar-default">
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded- lg:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 bg-primary">
                        <li>
                        {props.account !== null ? (
                            <Link to="/me">
                                <Link to="" className='navLinks rounded flex items-center gap-1'>
                                    <i className="fas hidden lg:block fa-heart"></i> Home
                                </Link>
                            </Link>
                        ):(
                            <Link to="/">
                                <Link to="" className='navLinks rounded flex items-center gap-1'>
                                    <i className="fas hidden lg:block fa-heart"></i> Home
                                </Link>
                            </Link>
                        )}
                        </li>
                        <li>
                            <Link to="/articles" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="fas hidden lg:block fa-newspaper"></i> News
                            </Link>
                        </li>
                        <li>
                            <Link to="/team/all" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="fas hidden lg:block fa-clipboard-user"></i> Staff
                            </Link>
                        </li>
                        <li>
                            <Link to="/vips" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="fas hidden lg:block fa-star"></i> Premium
                            </Link>
                        </li>
                        <li>
                            <Link to="/community/0" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="fas hidden lg:block fa-users"></i> Classifiche  
                            </Link>
                        </li>
                        <li>
                            <Link to="/catalog" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="fas hidden lg:block fa-loveseat"></i> Listino Prezzi  
                            </Link>
                        </li>
                        <li>
                            <Link to="/Regelwerk" className="py-2 px-3 text-white flex items-center rounded navLinks hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 gap-1">
                            <i className="&para"></i> § Regole
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}