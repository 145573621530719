import { useEffect, useState } from "react"
import useWebSocket from "react-use-websocket";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { MaintenanceComposer } from "../../packets/MaintenanceComposer";


const MaintenanceView = (props: any) => {
    const [staffAccessSection, setStaffAccessSection] = useState(false);
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    const [pinCode, setPinCode] = useState("");

    const sendAccessCode = () => {
        sendMessage(JSON.stringify(new MaintenanceComposer(pinCode)))
        setPinCode("");
    }

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "maintenanceResponse" && message.data !== null) {
                if(message.data.valid === "error") NotificationManager.error("Invalid details.", "Error");
                else props.disableMaintenance();
            }
        }
    }, [lastMessage, setMessageHistory]);

    return (
        <>
            <div className='animate__animated animate__fadeIn animate__slower' style={{ backgroundImage: "url('https://i.imgur.com/HA6yCmU.png'), linear-gradient(180deg, rgb(18, 18, 19) 0%, rgb(58, 59, 59) 100%)", backgroundRepeat: "no-repeat", backgroundPosition: "bottom right", backgroundAttachment: "fixed" }}>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossOrigin="anonymous" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4" crossOrigin="anonymous"></script>
                <div className="d-flex justify-content-md-center align-items-center vh-100">
                    <div className="col-md-5">
                        <div className="text-center">
                            <center> <img src={require("../../assets/images/logo.gif")} className="img-fluid mb-4 animate__animated animate__pulse animate__infinite" /></center>
                        </div>
                        <div className="card border-0 text-white card-shadow" style={{ backgroundColor: "rgba(0,0,0,0.6)" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        { staffAccessSection ? (
                                        <>
                                            <b>Pin:</b>
                                            <input value={pinCode} onChange={(e) => setPinCode(e.target.value)} className="form-control" type="password" placeholder="******" /><br/>
                                            <button onClick={sendAccessCode} data-bs-toggle="modal" data-bs-target="#maintenanceModal" className='btn btn-sm btn-dark'>Staff Login</button>&nbsp;&nbsp;
                                            <button onClick={() => setStaffAccessSection(false)} data-bs-toggle="modal" data-bs-target="#maintenanceModal" className='btn btn-sm btn-warning'>Back</button>

                                        </>) : (
                                            <>
                                                <h3><b>Das Hotel befindet sich derzeit im Aufbau</b></h3>
                                                <div>
                                                    Unsere Techniker setzen alles daran, damit dieses Hotel euren Erwartungen entspricht.<br /> Bist du bereit dein Abenteuer im <b>HABBET</b> zu beginnen?
                                                </div>
                                                <br />
                                                <p className="text-white mt-3">Check solange unsere Social Media Kanäle ab!</p>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <a target="_blank" href="https://discord.gg/v6RzPefY4p" className='btn btn-outline-light btn-sm'><i className="fab fa-discord"></i></a>&nbsp;
                                                    <a target="_blank" href="https://www.tiktok.com/@habbet.it?_t=8mO0rgLxzmL&_r=1" className='btn btn-outline-light btn-sm'><i className="fab fa-tiktok"></i></a>&nbsp;
                                                </div>
                                                <br />
                                                <button onClick={() => setStaffAccessSection(true)} data-bs-toggle="modal" data-bs-target="#maintenanceModal" className='btn btn-sm btn-dark'>Staff Login</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer style={{ whiteSpace: "pre-line" }} />
            </div>
        </>
    )
}

export default MaintenanceView
