import { FC, useEffect, useState } from "react";
import CatalogItemUserView from "./CatalogItemUserView";
import ModalCatalogItemView from "./ModalCatalogItemView";

interface catalogItemProps {
    item: any;
    canEdit: boolean;
}

const CatalogItemView: FC<catalogItemProps> = props => {
    const { item = [], canEdit = false } = props;

    function rBackdrop() {
        document.querySelector('.modal-backdrop')?.remove();
    }

    return (
        <>
            <div className='col-md-3 mb-4'>
                <div className='card primary-card text-white'>
                    <div className='card-body'>
                        <div className='catalog-furni-title mb-4'>{item.name}</div>
                        <div className='catalog-furni-card mt-2'>
                            <img className='catalog-furni-image' src={item.image} />
                        </div>
                        <div className='row gx-1 mt-4'>
                            <div className='col-md-6'>
                                <span className='badge bg-warning text-white text-center w-100 catalog-furni-size-price-bar'>
                                    <div className='catalog-furni-price'>{item.price_credits == 1 ? "Unbekannt" : item.price_credits}</div>
                                    <img src="https://images.habbet.it/images/wallet/-1.png" />
                                </span>
                            </div>
                            <div className='col-md-6'>
                                <span className='badge bg-info text-white text-center w-100 catalog-furni-size-price-bar'>
                                    <div className='catalog-furni-price'>{item.price_diamonds == 1 ? "Unbekannt" : item.price_diamonds}</div>
                                    <img src="https://images.habbet.it/images/wallet/5.png" />
                                </span>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-md-12'>
                                <button className='btn btn-dark w-100 btn-sm' data-bs-toggle="modal" data-bs-target={`#newRareCodeId-${item.id}`} data-dismiss="modal" data-backdrop="false" onClick={() => rBackdrop()}>Infos anzeigen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCatalogItemView item={item} canEdit={canEdit} />
            
        </>
    );
}

export default CatalogItemView;