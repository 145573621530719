class RegisterInfo{
    username: string;
    email: string;
    password: string;
    look: string;
    gender: string;

    constructor(username: string, email: string, password: string, look: string, genre: string){
        this.username = username;
        this.email = email;
        this.password = password;
        this.look = look;
        this.gender = genre;
    }
}

export class RegisterComposer{
    header: string;
    data: RegisterInfo;

    constructor(username: string, email: string, password: string, look: string, genre: string){
        this.header = "register";
        this.data = new RegisterInfo(username, email, password, look, genre);
    }
}