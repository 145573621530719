import React, { useEffect, useRef, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, Link } from 'react-router-dom';

import "./Dashboard.scss";
import LoadingView from '../loading/LoadingView';

import { timeSince, getPreviewImage,  getImagerForTops, getNewsBackgroundCss, getNewsBackgroundHref, getArticleRoute, getProfileUrl, LocalizeText } from '../../Globals';

import { NewsComposer } from '../../packets/NewsComposer';
import { TweetsComposer } from '../../packets/TweetsComposer';
import { CreateTweetComposer } from '../../packets/CreateTweetComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';
import { TopsComposer } from '../../packets/TopsComposer';
import { NotificationManager } from 'react-notifications';

const DashboardView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [effect, setEffect] = useState(30);
  const [background, setBackground] = useState("https://files.habboemotion.com/resources/images/website_backgrounds/bg_blue.gif");
  const [news, setNews] = useState<any>(null);
  const [tweets, setTweets] = useState<any>(null)
  const [postCount, setPostCount] = useState(0);
  const [postContent, setPostContent] = useState("");
  const [lastUsers, setLastUsers] = useState<any>(null);
  

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setPostCount(postContent.length)
  }, [postContent])

  useEffect(() => {
    if(props.account === null) navigate("/index", { replace: true });
  }, [props]);

  var isLoaded = false;
  useEffect(() => {
    if(isLoaded) return;
    isLoaded = true;
    document.title = "Dashboard | " + "Habbet"

    sendMessage(JSON.stringify(new NewsComposer()))
    sendMessage(JSON.stringify(new TweetsComposer()))
    sendMessage(JSON.stringify(new TopsComposer()))

    if(props.account === null) navigate("/index", { replace: true });
    if(window.localStorage.getItem("kubboch-sto") === null || window.sessionStorage.getItem("kubboch-stoc") === null) navigate("/index", { replace: true });
  }, [])

  
  useEffect(() => {
    if (lastMessage !== null) {
        var message = JSON.parse(lastMessage.data);

        if(message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
        if(message.header === "tweets" && message.data !== null) setTweets(JSON.parse(message.data.data));
        if(message.header === "createTweetResponse" && message.data !== null){
            if(message.data.data === "Tweet no valido") NotificationManager.error(LocalizeText(props.texts, 'tweet.error.invalid'), "Error")
            else if(message.data.data === "No intentes hacer publicidad!") NotificationManager.error(LocalizeText(props.texts, 'tweet.error.publi'), "Error")
            else if(message.data.data === "cooldown") NotificationManager.error(LocalizeText(props.texts, 'tweet.coldown'), "Error")
            else NotificationManager.success(LocalizeText(props.texts, 'tweet.success'), "OK")
        }
        if(message.header === "tweetResponse" && message.data !== null){
            if(message.data.data === "Error") NotificationManager.error(LocalizeText(props.texts, 'tweet.error'), "Error")
            else if(message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'tweet.error'), "Error")
            else NotificationManager.success(LocalizeText(props.texts, 'tweet.delete.success'), "OK")
        }

        if(message.header === "usersTops" && message.data !== null){
            setLastUsers(JSON.parse(message.data.lastUsers));
        }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if(news !== null && tweets !== null) setLoading(false)
  }, [news, tweets])

  const changeEfect = (effect) => {
    window.localStorage.setItem(global.localStorageMeEffect, effect);
    setEffect(effect);
  }

  const changeBackground = (bg) => {
    window.localStorage.setItem(global.localStorageMeBackground, bg);
    setBackground(bg);
  }

  const getPreviewEffect = (effect) => global.imagerUrl+props.account.look+"&direction=3&head_direction=3&effect="+effect+"&img_format=gif";
  const getImagerLook = (look) => global.imagerUrl+look+"&direction=3&head_direction=3&size=l&effect="+effect+"&img_format=gif";
  
  const createTweet = () => {
    if(postContent.length > 250){
        NotificationManager.error(LocalizeText(props.texts, 'tweet.length'), "Error")
        return;
    }
    
    if(postContent === ""){
        NotificationManager.error(LocalizeText(props.texts, 'tweet.empty'), "Error");
        return;
    }

    sendMessage(JSON.stringify(new CreateTweetComposer(postContent)))
    sendMessage(JSON.stringify(new TweetsComposer()))
    setPostContent("");
  }

  const deleteTweet = (id) => {
    sendMessage(JSON.stringify(new DeleteTweetComposer(id)))
    sendMessage(JSON.stringify(new TweetsComposer()))
  }

  // lariesse 

  const [modalOpen, setModalOpen] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    if (modalContentRef.current && modalContentRef.current.contains(e.target as Node)) {
      return;
    }

    setModalOpen(false);
  };

  //

  return (
    <>
        {props.account != null &&
        <>
            { loading ? <LoadingView /> :(
                <>
                    <div className='max-w-7xl mx-auto grid grid-cols-12 gap-4 animate__animated animate__fadeIn animate__slower mt-5'>
                            <div className="col-span-12 md:col-span-8 mt-3 flex flex-col">
                                <div className="flex items-center p-2  bg-primary rounded justify-between">
                                    <div className="p-2">{LocalizeText(props.texts, 'tweet.feed')}</div>
                                    <button className='bg-secondary rounded p-2' onClick={openModal}>{LocalizeText(props.texts, 'tweet.create')}</button>
                                </div>
                                <div className="flex flex-col gap-2 max-h-96 overflow-y-auto mt-3 bg-primary p-3 rounded">
                                  {tweets !== null &&
                                      <>
                                          {tweets.map((tweet) =>
                                              <div key={tweet.id} className="mb-12">
                                                  <div className="flex items-center gap-3 mt-1">
                                                      <Link to={getProfileUrl(tweet.username)} className="!bg-center rounded-[50%] min-w-[64px] max-w-[64px] max-h-[64px] min-h-[64px] group overflow-hidden" style={{ background: "url(https://i.imgur.com/a4VVjmO.png)", boxShadow: "0 0 0 5px rgba(0, 0, 0, .20)" }}>
                                                          <div className="!w-[64px] group-hover:-translate-y-2 transition-all h-[110px] -mt-2" style={{ backgroundImage: getImagerForTops(tweet.look) }}></div>
                                                      </Link>
                                                      <div className="flex w-full relative flex-col gap-1">
                                                          <div className="w-full p-5 rounded bg-secondary">{tweet.tweetText}</div>
                                                          <div className="absolute -bottom-10 text-sm right-1 flex gap-2 items-center">
                                                              <span>{LocalizeText(props.texts, 'tweet.author')} {tweet.username}</span>
                                                              <span className='bg-secondary bg-opacity-50 p-2 rounded'>{LocalizeText(props.texts, 'tweet.date')} {timeSince(tweet.date)}</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          )}
                                      </>
                                  }
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-4 flex mt-3 flex-col">
                                <div className="p-4 bg-primary rounded">{LocalizeText(props.texts, 'discord.title')} <i className="fab fa-discord"></i></div>
                                  <div className="flex flex-col gap-2 max-h-96 overflow-y-auto mt-3 bg-primary p-3 rounded">
                                  { props.discord !== null && props.discord.members.map((userDc) =>  
                                            <div className="grid grid-cols-12" key={Math.random()}>
                                                <div className="col-span-8">
                                                <img src={userDc.avatar_url} style={{borderRadius: "50%", height: "40px", display: "inline-block"}}/>
                                                <div style={{marginTop: "9px", marginLeft: "15px", display:"inline-block"}} ><b>{userDc.username}</b></div>
                                                </div>
                                                <div className="col-span-4">
                                                <div style={{float: "right", fontSize: "20px", marginTop: "6px"}}>
                                                    {userDc.status === "online" &&
                                                        <i className="fad fa-circle text-success" aria-hidden="true"></i>
                                                    }
                                                    {userDc.status === "dnd" &&
                                                        <i className="fad fa-circle text-danger" aria-hidden="true"></i>
                                                    }
                                                    {userDc.status === "idle" &&
                                                        <i className="fad fa-circle text-warning" aria-hidden="true"></i>
                                                    }
                                                </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                  </div>
                            </div>
                            { news != null &&
                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                <div className="col-span-12 md:col-span-3">
                                  {news[0] != null &&
                                      <Link to={getArticleRoute(news[0].id, news[0].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                          <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                              <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                  Updates
                                              </div>
                                              <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                  {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}
                                              </div>
                                          </div>
                                          <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                              <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[0].title}</h1>
                                              <h2 className='text-md opacity-80 font-light'>{news[0].shortStory}</h2>
                                          </div>
                                      </Link>
                                  }
                                </div>
                                <div className="col-span-12 md:col-span-3">
                                  {news[0] != null &&
                                      <Link to={getArticleRoute(news[0].id, news[0].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                          <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                              <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                  Updates
                                              </div>
                                              <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                  {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}
                                              </div>
                                          </div>
                                          <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                              <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[0].title}</h1>
                                              <h2 className='text-md opacity-80 font-light'>{news[0].shortStory}</h2>
                                          </div>
                                      </Link>
                                  }
                                </div>
                                <div className="col-span-12 md:col-span-3">
                                  {news[0] != null &&
                                      <Link to={getArticleRoute(news[0].id, news[0].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                          <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                              <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                  Updates
                                              </div>
                                              <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                  {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}
                                              </div>
                                          </div>
                                          <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                              <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[0].title}</h1>
                                              <h2 className='text-md opacity-80 font-light'>{news[0].shortStory}</h2>
                                          </div>
                                      </Link>
                                  }
                                </div>
                                <div className="col-span-12 md:col-span-3">
                                  {news[0] != null &&
                                      <Link to={getArticleRoute(news[0].id, news[0].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                          <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                              <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                  Updates
                                              </div>
                                              <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                  {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}
                                              </div>
                                          </div>
                                          <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                              <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[0].title}</h1>
                                              <h2 className='text-md opacity-80 font-light'>{news[0].shortStory}</h2>
                                          </div>
                                      </Link>
                                  }
                                </div>
                                
                            </div>
                        }
                        </div>

                      {modalOpen && (
                          <div className="modal-overlay modal-overlay flex  justify-center items-center fixed top-0 left-0 bottom-0 bg-secondary w-full h-full bg-opacity-50 backdrop-blur-[2px]" onClick={closeModal}>
                              <div className="modal-content animate__animated animate__fadeInDown bg-primary max-w-2xl rounded p-2 w-full flex flex-col" ref={modalContentRef}>
                              <div className="modalHeader flex justify-between p-3 bg-secondary rounded">
                                    <p className='font-bold'>{LocalizeText(props.texts, 'tweet.create.header')}</p>
                                    <button onClick={() => setModalOpen(false)}>X</button>
                                </div>
                                <div className="p-2 flex flex-col gap-2 ">
                                    <textarea value={postContent} onChange={e => setPostContent(e.target.value)} className="jeInput rounded w-full" placeholder={LocalizeText(props.texts, 'tweet.txt')}></textarea>
                                    <div className="flex justify-between">
                                        <p>{LocalizeText(props.texts, 'tweet.length.max')}</p>
                                        <p>{postCount}/250</p>
                                    </div>
                                    <button onClick={() => createTweet()} className="w-full rounded p-2 jeHeaderLoginButton" data-bs-dismiss="modal" aria-label="Close">{LocalizeText(props.texts, 'tweet.create.button')}</button>
                              </div>
                              </div>
                          </div>
                      )}
                    <div className="modal fade hidden" id="newPostModal" tabIndex={-1} aria-labelledby="newPostModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content primary-card">
                                <div className="modal-header primary-header-card">
                                    <h5 className="modal-title" id="newPostModalLabel">{LocalizeText(props.texts, 'tweet.create.header')}</h5>
                                    <button type="button" className="btn btn-danger danger-habbo btn-sm" data-bs-dismiss="modal" aria-label="Close">x</button>
                                </div>
                                <div className="modal-body text-white">
                                    <textarea value={postContent} onChange={e => setPostContent(e.target.value)} className="form-control" placeholder={LocalizeText(props.texts, 'tweet.txt')}></textarea>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                        {LocalizeText(props.texts, 'tweet.length.max')}
                                        </div>
                                        <div>
                                            {postCount}/250
                                        </div>
                                    </div>
                                    <button onClick={() => createTweet()} className="btn btn-kubbo-success w-100" data-bs-dismiss="modal" aria-label="Close">{LocalizeText(props.texts, 'tweet.create.button')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
        }
    </>
  )
}

export default DashboardView
