import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Link } from 'react-router-dom';

import "./Article.scss"
import LoadingView from '../loading/LoadingView';

import { NewsComposer } from '../../packets/NewsComposer';
import { getArticleRoute, timeSince, LocalizeText } from '../../Globals';


const ArticlesView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [news, setNews] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  var isLoaded = false;
  useEffect(() => {
    if(isLoaded) return;
    isLoaded = true;
    document.title = LocalizeText(props.texts, 'article.title') + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
        var message = JSON.parse(lastMessage.data);

        if(message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if(news !== null) setLoading(false)
  }, [news])

  return (
    <>
      { loading ? <LoadingView /> :(
        <>
          <div className='max-w-7xl gap-4 w-full mx-auto grid grid-cols-12 container-dashboard animate__animated animate__fadeIn animate__slower mt-5'>
              <div className="col-span-12 md:col-span-8">
                {news !== null && news.length !== 0 &&
                  <>
                    {news.map((article) => 
                      <div key={article.id} className="w-full flex gap-3 items-center h-36">
                        <div className="h-full min-w-36 max-w-36 !bg-center bg-black" style={{ backgroundImage: `url(${article.header})` }}></div>
                        <div className="w-full overflow-hidden flex flex-col gap-2">
                          <p className='text-xl md:text-3xl'>{article.title}</p>
                          <p className='truncate w-full'><i>{article.shortStory} | <span className="text-white">{LocalizeText(props.texts, 'article.post.date')} {timeSince(article.date)}</span></i></p>
                          <Link className="btn btn-kubbo-warning !bg-primary btn-lg max-w-fit" to={getArticleRoute(article.id, article.title)} style={{ backgroundColor: "#7f7f80", color: "white", textDecoration: "none", padding: "10px 20px", borderRadius: "5px" }}>{LocalizeText(props.texts, 'article.view.button')}</Link>
                        </div>
                      </div>
                    )}
                  </>
                }
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <div className="p-2 bg-primary rounded">{LocalizeText(props.texts, 'article.faq.header')}</div>
                  <p className='bg-primary flex justify-between p-2 rounded'>
                    {LocalizeText(props.texts, 'article.faq.txt1')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt2')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt3')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt4')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt5')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt6')}<br />
                    <img className='floatright hidden md:block' src="https://www.habboassets.com/assets/images/dump/com/web_promo_small/spromo_steampunkbundle.png" />
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="p-2 bg-primary rounded">{LocalizeText(props.texts, 'article.faq2.header')}</div>
                  <p className='bg-primary p-2 rounded flex items-center'>
                  <img className='floatleft hidden md:block' style={{marginTop: "17px"}} src="https://1.bp.blogspot.com/-o63K5F9L4LU/X5js-YSVLlI/AAAAAAABemk/KtQwXDo03hwHAAdw4g1HeEXKCaEvs5dQACPcBGAsYHg/s0/image_frank_introduce.png" />
                    {LocalizeText(props.texts, 'article.faq.txt1')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt2')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt3')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt4')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt5')}<br />
                    {LocalizeText(props.texts, 'article.faq.txt6')}<br />
                  </p>
                </div>
              </div>
          </div>
        </>
      )}
    </>
  )
}

export default ArticlesView