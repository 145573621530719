class Data{

}

export class StaffComposer{
    header: string;
    data: Data;

    constructor(){
        this.header = "staff";
        this.data = new Data();
    }
}