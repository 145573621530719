import React,{useState} from 'react'
import { timeSince } from '../../Globals';

const LastRegisterView = (props) => {
  const [showContext, setShowContext] = useState(false);

  return (
    <div onMouseEnter={() => setShowContext(true)} onMouseLeave={() => setShowContext(false)} className="last-registers-item-bg dark-habbo-outline bg-dark">
        <div className='last-registers-item' style={{backgroundPosition: "50% 20%", backgroundImage: `url(https://nitro-imager.habbet.it/?figure=${props.look}&direction=3&head_direction=3&headonly=1)`}} />
        {showContext &&
            <div className='last-registers-context'>
                <div><b>{props.name}</b></div>
                <div><small>{props.motto}</small></div>
                <div style={{color: "#e3e3e3"}}><small>Registrato dal {timeSince(props.quantity)}</small></div>
            </div>
        }
    </div>
  )
}

export default LastRegisterView
