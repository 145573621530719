import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { getArticleRoute, LocalizeText, timeSince } from '../../Globals'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { TopsComposer } from '../../packets/TopsComposer';
import { NewsComposer } from '../../packets/NewsComposer';

import "./Welcome.scss";
import LoadingView from '../loading/LoadingView';
import LastRegisterView from './LastRegisterView';

interface WelcomeProps {
    texts: { [key: string]: string };
    account: any;
}

const WelcomeView = (props: WelcomeProps) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [news, setNews] = useState<any[]>([]);
    const [lastRegisters, setLastRegisters] = useState<any[]>([]);
    const [diamonds, setDiamonds] = useState<any[]>([]);
    const [credits, setCredits] = useState<any[]>([]);
    const [kubbitos, setKubbitos] = useState<any[]>([]);

    const [tops, setTops] = useState<any>([]);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        sendMessage(JSON.stringify(new TopsComposer()))
        sendMessage(JSON.stringify(new NewsComposer()))
    }, []);

    useEffect(() => {
        if (props.account !== null) navigate("/me", { replace: true });
    }, [props]);

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);
            if (message.header === "usersTops" && message.data !== null) {
                setDiamonds(JSON.parse(message.data.diamonds));
                setKubbitos(JSON.parse(message.data.currency));
                setCredits(JSON.parse(message.data.credits));
                setLastRegisters(JSON.parse(message.data.lastUsers))
                setTops(message.data)
            }
            if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        if (tops.length !== 0 && news.length !== 0) setLoaded(true);
    }, [tops, news]);

    return (
        <>
            {!loaded ? <LoadingView /> : (
                <div className='max-w-7xl w-full mt-2 mx-auto animate__animated animate__fadeIn grid grid-cols-12 gap-4'>
                    {news.length !== 0 &&
                        <div className="col-span-12 grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-3">
                                {news[0] != null &&
                                    <Link to={getArticleRoute(news[0].id, news[0].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                        <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                            <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                Updates
                                            </div>
                                            <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}
                                            </div>
                                        </div>
                                        <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                            <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[0].title}</h1>
                                            <h2 className='text-md opacity-80 font-light'>{news[0].shortStory}</h2>
                                        </div>
                                    </Link>
                                }
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                {news[1] != null &&
                                    <Link to={getArticleRoute(news[1].id, news[1].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                        <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                            <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                Updates
                                            </div>
                                            <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[1].date)}
                                            </div>
                                        </div>
                                        <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                            <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[1].title}</h1>
                                            <h2 className='text-md opacity-80 font-light'>{news[1].shortStory}</h2>
                                        </div>
                                    </Link>
                                }
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                {news[2] != null &&
                                    <Link to={getArticleRoute(news[2].id, news[2].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                        <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                            <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                Updates
                                            </div>
                                            <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[2].date)}
                                            </div>
                                        </div>
                                        <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                            <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[2].title}</h1>
                                            <h2 className='text-md opacity-80 font-light'>{news[2].shortStory}</h2>
                                        </div>
                                    </Link>
                                }
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                {news[3] != null &&
                                    <Link to={getArticleRoute(news[3].id, news[3].title)} className='w-full cursor-pointer card !bg-center bg-white h-72 flex flex-col articlenewsimage justify-between rounded  transition ease-in-out duration-200 hover:scale-[101%]' style={{ backgroundImage: 'linear-gradient(180deg, #00000000, #000000eb), url(https://images.habbo.com/web_images/habbo-web-articles/lpromo_Jan23.png)' }}>
                                        <div id="article-header" className='w-full flex h-16 px-5 items-center justify-between'>
                                            <div id="article-header-category" className='w-24 flex items-center justify-center text-white font-bold	 bg-white bg-opacity-25 h-10 rounded-full'>
                                                Updates
                                            </div>
                                            <div id="article-header-category" className='w-24 opacity-90 text-sm text-white font-bold h-10 flex items-center justify-center'>
                                                {LocalizeText(props.texts, 'article.post.date')} {timeSince(news[3].date)}
                                            </div>
                                        </div>
                                        <div id='article-title' className='w-full flex h-28 items-center justify-center px-4flex text-white flex-col break-words '>
                                            <h1 className='font-bold text-2xl lg:text-xl xl:text-2xl sm:text-base'>{news[3].title}</h1>
                                            <h2 className='text-md opacity-80 font-light'>{news[3].shortStory}</h2>
                                        </div>
                                    </Link>
                                }
                            </div>

                        </div>
                    }
                    {tops != null &&
                        <div className="col-span-12 grid grid-cols-12 gap-2">
                            <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                                <div className="p-2 bg-primary rounded">Utenti con più Diamanti</div>
                                <div className="h-52 relative rounded !bg-center bg-black p-4 flex items-center justify-center" style={{background:"url(https://i.imgur.com/JHBzpo2.png), #181818"}}>
                                    <center>
                                        <img className='!mt-[4.8rem]' src="https://i.imgur.com/D6Bl4xM.png" alt="" />
                                        <img style={{ position: "absolute", marginTop: "-173px", marginLeft: "32px" }} src={`https://nitro-imager.habbet.it/?figure=${diamonds[0].look}&direction=3&head_direction=3&img_format=gif`} />
                                        <img style={{ position: "absolute", marginTop: "-146px", marginLeft: "-3x" }} src={`https://nitro-imager.habbet.it/?figure=${diamonds[1].look}&direction=2&head_direction=2&img_format=gif`} />
                                                <img style={{ position: "absolute", marginTop: "-134px", marginLeft: "64px" }} src={`https://nitro-imager.habbet.it/?figure=${diamonds[2].look}&direction=4&head_direction=4&img_format=gif`} />
                                    </center>
                                </div>

                            </div>
                            
                            <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                                <div className="p-2 bg-primary rounded">Utenti con più crediti</div>
                                <div className="h-52 relative rounded !bg-center bg-black p-4 flex items-center justify-center" style={{background:"url(https://i.imgur.com/JHBzpo2.png), #181818"}}>
                                    <center>
                                        <img className='!mt-[4.8rem]' src="https://i.imgur.com/D6Bl4xM.png" alt="" />
                                        <img style={{ position: "absolute", marginTop: "-173px", marginLeft: "32px" }} src={`https://nitro-imager.habbet.it/?figure=${credits[0].look}&direction=3&head_direction=3&img_format=gif`} />
                                        <img style={{ position: "absolute", marginTop: "-146px", marginLeft: "-3x" }} src={`https://nitro-imager.habbet.it/?figure=${credits[1].look}&direction=2&head_direction=2&img_format=gif`} />
                                                <img style={{ position: "absolute", marginTop: "-134px", marginLeft: "64px" }} src={`https://nitro-imager.habbet.it/?figure=${credits[2].look}&direction=4&head_direction=4&img_format=gif`} />
                                    </center>
                                </div>

                            </div>
                            <div className='col-span-12 flex flex-col gap-2'>
                                <div className="p-2 bg-primary rounded">Ultimo registrato</div>
                                        <div className='last-registers-box justify-between !md:justify-start'>
                                            {lastRegisters.map((user) =>
                                                <LastRegisterView motto={user.motto} quantity={user.amount} name={user.username} look={user.look} key={user.id} />
                                            )}
                                        </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </>
    )
}

export default WelcomeView
