class Note{
    id: number;
    text: string;
    x: number;
    y: number;
    
    constructor(id, text, x, y){
        this.id = id;
        this.text = text;
        this.x = x;
        this.y = y;
    }
}

export default Note