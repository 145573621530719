import React from 'react';

function Regeln() {
  return (
    <div className="max-w-7xl mx-auto w-full mt-5 bg-primary p-2 rounded">
      <h1 className="text-center">Regole Generali</h1>
      <div className="text-center">
        <p className="lead">Si prega di notare le seguenti regole:</p>
      </div>
      <div className="">
        <div className="flex flex-col gap-2">
          <p><strong>§1 Divieto di manipolazione da parte dei dipendenti e di diffusione di informazioni palesemente false:</strong><br />
          È severamente vietato mentire a un dipendente o presentare deliberatamente fatti falsi.</p>
          <p><strong>§2 Comportamenti vietati: insulti, continue provocazioni e incitamenti:</strong><br />
          Insulti, provocazioni ripetute o discorsi di odio nei confronti di altri utenti sono inaccettabili e proibiti.</p>
          <p><strong>§3 Frode relativa alla password:</strong><br />
          È severamente vietato oscurare i dati dell'account o accedere non autorizzato ad account di terze parti tramite frodi relative alla password.</p>
          <p><strong>§4 Dichiarazioni vietate: sessismo, razzismo e discriminazione:</strong><br />
          Dichiarazioni di natura sessuale, razzista o discriminatoria che riguardino determinati gruppi etnici o religioni non sono in alcun modo tollerate e sono severamente vietate.</p>
          <p><strong>§5 Divieto di contenuti inappropriati delle stanze (come anche i nomi delle stanze):</strong><br />
          La creazione di stanze con contenuti inappropriati non è consentita e sarà severamente vietata.</p>
          <p><strong>§6 Divieto di nomi utente inappropriati:</strong><br />
          L'uso di un nome utente inappropriato non è consentito e deve essere evitato.</p>
          <p><strong>§7 Divieto di frode d'identità o di affiliazione ingannevole alla squadra:</strong><br />
          Impersonare una falsa identità o fingere di essere un membro dello staff è severamente vietato e sarà punito di conseguenza.</p>
        </div>
        <div className="flex flex-col gap-2">
          <p><strong>§8 Pubblicità di terzi:</strong><br />
          Pubblicizzare altri hotel o condividere collegamenti a contenuti inappropriati non è consentito ed è considerato pubblicità di terze parti.</p>
          <p><strong>§9 Conto multiplo:</strong><br />
          È consentito l'utilizzo di un massimo di due account, ma non è valida la regola “Per IP 2 Utente”.</p>
          <p><strong>§10 Utilizzo di strumenti di frode:</strong><br />
          L'uso di programmi che forniscono un vantaggio sleale nel gioco è severamente proibito.</p>
          <p><strong>§11 Divieto di pubblicazione e diffusione di dati privati:</strong><br />
          La pubblicazione o distribuzione di dettagli di contatto privati, immagini o altre informazioni personali è severamente vietata e sarà punita con conseguenze.</p>
          <p><strong>§12 Scambio vietato di denaro reale tra utenti:</strong><br />
          Lo scambio di beni digitali con denaro reale tra utenti non è consentito ed è considerato una violazione delle regole.</p>
          <p><strong>§13 Riciclaggio di denaro:</strong><br />
          Non è consentito aumentare Crediti, Ruby o rari creando più account o metodi simili.</p>
        </div>
      </div>
      <div className="text-center mt-5">
        <h3>Le regole sono vincolanti per tutti gli utenti dell'Habbet Hotel:</h3>
        <ul>
        Se le regole vengono violate, possono essere adottate misure come la sospensione o il ban dell'account.
        Le regole possono essere aggiornate in qualsiasi momento.
        È responsabilità degli utenti mantenersi informati di eventuali modifiche.
        </ul>
        <h3>Contatto:</h3>
        <p>In caso di domande o segnalazioni di violazioni delle regole, contattare il nostro supporto.</p>
        <footer>
          &copy; © 2024 HABBET. Tutti i diritti riservati.
        </footer>
      </div>
    </div>
  );
}

export default Regeln;
