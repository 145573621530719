class LoginInfo{
    code: string;

    constructor(code: string){
        this.code = code;
    }
}

export class MaintenanceComposer{
    header: string;
    data: LoginInfo;

    constructor(code: string){
        this.header = "maintenance";
        this.data = new LoginInfo(code);
    }
}