import React, { useState } from 'react'
import { timeSince } from '../../Globals';
import { Link } from 'react-router-dom';

const VipUserItemView = (props) => {
    const [onMouse, setOnMouse] = useState(false);

    return (
        <Link to={"/profile/" + props.username} className="col-span-12 md:col-span-6 overflow-hidden flex items-center h-24 rounded hover:-translate-y-1 transition-all bg-primary" >
            <div className="min-w-32 h-full flex items-center max-w-32 bg-secondary">
                <img className='!w-[128px] h-[220px] mt-12'  src={`https://nitro-imager.habbet.it/?figure=${props.look}&direction=3&size=l&head_direction=3${onMouse ? "&action=wlk,wav,drk=1" : ""}`}  />
            </div>
            <div className="flex flex-col p-3 w-full h-full">
                <p className='text-3xl'>{props.username}</p>
                <p className='text-xs text-opacity-70 text-white mt-5 italic'>Danke für deine Unterstützung!</p>
            </div>
        </Link>
    )
}

export default VipUserItemView
