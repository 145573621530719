import React, { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

import "./Register.scss";
import { RegisterComposer } from '../../packets/RegisterComposer';
import { LocalizeText } from '../../Globals';

interface RegisterNewProps {
  texts: { [key: string]: string };
}

const RegisterNewView = (props: RegisterNewProps) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [avatar, setAvatar] = useState("");
  const [genre, setGenre] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = LocalizeText(props.texts, 'register.title') + " | Habbet"
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);
      if (message.header === "registerResponse" && message.data != null) {
        if (message.data.data === "Este usuario ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.user.invalid'), "Error");
        else if (message.data.data === "Este correo ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.email.invalid'), "Error");
        else if (message.data.data === "La contraseña debe ser mayor a 6 digitos.") NotificationManager.error(LocalizeText(props.texts, 'register.password.invalid'), "Error");
        else if (message.data.data === "Ocurrio un error al crear la cuenta.") NotificationManager.error(LocalizeText(props.texts, 'register.error'), "Error");
        else {
          NotificationManager.success(LocalizeText(props.texts, 'register.success'), "OK");
          window.localStorage.setItem("kubboch-sto", message.data.data);
          navigate("/me", { replace: true });
        }
      }
    }
  }, [lastMessage, setMessageHistory]);

  const chooseAvatar = (av, genre) => {
    setAvatar(av);
    setGenre(genre);

    document.getElementById("firstStep")?.classList.add("hide-content");
    document.getElementById("secondStep")?.classList.remove("hide-content");
  }

  const chooseAccountData = () => {
    if (username === "" || email === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    document.getElementById("secondStep")?.classList.add("hide-content");
    document.getElementById("thirdStep")?.classList.remove("hide-content");
  }

  const getImagerDance = () => {
    return "https://nitro-imager.habbet.it/?figure=" + avatar + "&direction=2&head_direction=2&size=l&dance=1&img_format=gif"
  }

  const getImagerNave = () => {
    return "https://nitro-imager.habbet.it/?figure=" + avatar + "&direction=2&head_direction=2&size=l&effect=3&img_format=gif"
  }

  const register = () => {
    if (username === "" || email === "" || password === "" || confirmPassword === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    if (username.includes(" ")) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput2'), "Error");
      return;
    }

    if (password !== confirmPassword) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput3'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new RegisterComposer(username, email, password, avatar, genre)));
  }

  return (
    <div className='w-full max-w-md gap-5 mx-auto animate__animated animate__fadeIn bg-primary p-2 mt-5 rounded flex flex-col'>
      <div className="flex items-center gap-2">
        <img src="https://i.imgur.com/4VAg1xn.png" alt="" />
        <div className="flex flex-col gap-1">
          <p className='text-2xl font-extrabold'>Registrati Habbet</p>
          <p className='text-white text-opacity-80'>Crea il tuo Account Gratuito ed entra in un fantastico mondo virtuale!</p>
        </div>
      </div>
      <div className="w-full h-1 bg-secondary"></div>
      <div className="flex flex-col">
        <div id="firstStep" className="register-center-elements flex items-center justify-center flex-col text-center animate__animated animate__fadeIn animate__slower">
          <div className="register-box-title text-center">
            {LocalizeText(props.texts, 'register1.title')}
          </div>
          <center className='flex'>
            <img onClick={() => chooseAvatar("ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa", "M")} className='choose-avatar-img' src="https://nitro-imager.habbet.it/?figure=ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa&direction=3&head_direction=3&action=wave" />
            <img onClick={() => chooseAvatar("hr-8596-1e1e1e.hd-600-eea768.ch-655-ffba00.lg-695-536eb0.sh-725-ffffff", "F")} className='choose-avatar-img' src="https://nitro-imager.habbet.it/?figure=hr-8596-1e1e1e.hd-600-eea768.ch-655-ffba00.lg-695-536eb0.sh-725-ffffff&direction=3&head_direction=3&action=wave" />
          </center>
          <div id="firstTip" className="register-tip !bg-secondary animate__animated animate__fadeInDown">{LocalizeText(props.texts, 'register1.txt')}</div>
        </div>
        <div id="secondStep" className='hide-content'>
          <div className="animate__animated animate__fadeIn animate__slower">
            <div className="register-box-title2 !text-3xl text-center !-mt-3">{LocalizeText(props.texts, 'register2.title')}</div>
            <div className="flex ">
              <img className='w-[128px] h-[220px] hidden md:block' src={getImagerDance()} />
              <div>
                <div className="register-name-content">
                  {LocalizeText(props.texts, 'register2.username')}
                  <input onChange={(e) => setUsername(e.target.value)} className="jeInput input-login-username" type="text" placeholder={LocalizeText(props.texts, 'login.register.placeholder.username')} /><br />
                  {LocalizeText(props.texts, 'register2.email')}
                  <input onChange={(e) => setEmail(e.target.value)} className="jeInput input-login-password" type="email" placeholder={LocalizeText(props.texts, 'login.register.placeholder.email')} />
                </div>

                <button onClick={() => chooseAccountData()} className="jeHeaderLoginButton p-2 rounded w-full mt-2">{LocalizeText(props.texts, 'register2.button')}</button>
              </div>
            </div>
            <center>
              <h2><span className="badge bg-dark">2/3</span></h2>
            </center>
          </div>
        </div>
        <div id="thirdStep" className='hide-content'>
        <div className="animate__animated animate__fadeIn animate__slower">
            <div className="register-box-title2 !text-3xl text-center !-mt-3">{LocalizeText(props.texts, 'register3.title')}</div>
            <div className="flex ">
              <img className='w-[128px] h-[220px] hidden md:block' src={getImagerNave()} />
              <div>
                <div className="register-name-content">
                  {LocalizeText(props.texts, 'register3.password')}
                  <input onChange={(e) => setPassword(e.target.value)} className="jeInput input-login-username" type="password" placeholder="**********" /><br/>
                  {LocalizeText(props.texts, 'register3.rpassword')}
                  <input onChange={(e) => setConfirmPassword(e.target.value)} className="jeInput input-login-password" type="password" placeholder="**********" />
                </div>

                <button onClick={() => register()} className="jeHeaderLoginButton p-2 rounded w-full mt-2">{LocalizeText(props.texts, 'register3.button')}</button>
              </div>
            </div>
            <center>
            <h2><span className="badge bg-dark">3/3</span></h2>
            </center>
          </div>
                </div>
      </div>
    </div>
  )
}

export default RegisterNewView
