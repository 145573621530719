import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import "./Footer.scss";
import HotelLogo from "../../assets/images/logo.png";

const FooterView = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/play"){
      setIsVisible(false);
    }
    else setIsVisible(true);

  }, [location]);

  return (
    <>
    {isVisible &&
    <>
        <div className='w-full p-8  bg-primary '>
            <div className="w-full max-w-7xl flex flex-col md:flex-row justify-between mx-auto px-6 gap-3">
              <div className="flex flex-col md:flex-row items-center gap-2">
                <img src="https://i.imgur.com/4VAg1xn.png" alt="" />
                <div className="flex flex-col text-center md:text-start">
                  <p className='text-2xl font-extrabold'>HABBET 2024</p>
                  <p className='text-sm'>HABBET CMS is a Habbo Retro CMS compatible with Arcturus Morningstar developed with React and Java.</p>
                </div>
              </div>
              <div className="flex items-center gap-4 justify-center">
                <a href="https://discord.gg/v6RzPefY4p" target="_blank">
                  <i className="fa-brands fa-discord text-3xl"></i>
                </a>
                <a href="https://www.tiktok.com/@habbet.it?_t=8mO0rgLxzmL&_r=1" target="_blank">
                  <i className="fa-brands fa-tiktok text-3xl"></i>
                </a>
                
              </div>
            </div>
        </div>
    </>
    }
    
    </>
  )
}

export default FooterView
