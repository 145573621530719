class Sticker{
    id: number;
    url: string;
    x: number;
    y: number;
    
    constructor(id, url, x, y){
        this.id = id;
        this.url = url;
        this.x = x;
        this.y = y;
    }
}

export default Sticker