import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

import HotelLogo from "../../assets/images/logo.png";
import { LocalizeText } from '../../Globals';
import LoginModalView from '../auth/LoginModalView';

export const NavbarView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [clientInit, setclientInit] = useState(false);
    const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible")

    const logout = () => {
        props.logout();
        navigate("/index", { replace: true });
    }

    useEffect(() => {
        if (location.pathname === "/play") {
            setclientInit(true);
            setVisibility("visible");
        }
        else setVisibility("hidden");

    }, [location]);

    const getSrcIframe = (sso) => "https://nitro.habbet.it/index.html?sso=" + sso;

    return (
        <>
            <nav className="relative bg-black" style={{background:"url(https://i.imgur.com/a4VVjmO.png)"}}>
                <div className="bg-black relative p-5 md:p-2 bg-opacity-50 w-full">
                    <div className="max-w-7xl mx-auto flex flex-col md:flex-row  gap-4 justify-center md:justify-between w-full">
                        <img className='w-[200.100px] h-[60px] mx-auto md:mx-0' src="https://i.imgur.com/HedGbay.gif" alt="" />
                        <div className="flex items-center justify-center md:justify-normal gap-2 w-full md:w-auto">
                        {props.account === null ? (
                            <>
                                <Link to="/register">
                                    <a  href="#" className='jeHeaderRegisterButton font-bold rounded p-2'>
                                        <i className="fas fa-user-plus"></i> Registrati
                                    </a>
                                </Link>
                                <Link to="/login">
    <a href="#" className='jeHeaderLoginButton font-bold rounded p-2'>
        <i className="fas fa-sign-in-alt"></i> Entra
    </a>
</Link>
                            </>
                        ) : (
                            <>
                                <Link to="/play">
                                    <a style={{backgroundColor: "#399f4f", color: "white" }} href="#" className='btn btn-kubbo flex items-center gap-2 rounded btn-kubbo-warning'>
                                        <i className="fas fa-sign-in-alt"></i>
                                        <span className='hidden md:block transition-all'> Gioca</span>
                                    </a>
                                </Link>
                                <Link to={`/profile/${props.account.username}`}>
                                    <a style={{ marginRight: "10px" }} href="#" className='btn btn-kubbo flex items-center gap-2 rounded btn-kubbo-dark'>
                                        <i className="fas fa-cog"></i>
                                        <span className='hidden md:block transition-all'>Profilo</span>
                                    </a>
                                </Link>
                                <Link to={`/settings`}>
                                    <a style={{ marginRight: "10px" }} href="#" className='btn btn-kubbo flex items-center gap-2 rounded btn-kubbo-dark'>
                                        <i className="fas fa-cog"></i>
                                        <span className='hidden md:block transition-all'>Impostazioni</span>
                                    </a>
                                </Link>
                                <a onClick={() => logout()} style={{ marginRight: "10px" }} href="#" className='btn btn-kubbo flex items-center gap-2 rounded btn-kubbo-danger'>
                                    <i className="fas fa-sign-out-alt"></i>
                                    <span className='hidden md:block transition-all'>Disconnetti</span>
                                </a>
                            </>
                        )}
                        </div>
                    </div>
                </div>
            </nav>
            {props.account !== null && clientInit &&
                <>
                    {visibility === "visible" && <Link className='back-button btn-sm text-center' to="/me">{LocalizeText(props.texts, 'play.home.btn')}</Link>}

                    <div style={{overflow: "hidden"}}>
                        <iframe allow="
                            accelerometer *; 
                            ambient-light-sensor *; 
                            autoplay *; 
                            camera *; 
                            clipboard-read *; 
                            clipboard-write *; 
                            encrypted-media *; 
                            fullscreen *; 
                            geolocation *; 
                            gyroscope *; 
                            magnetometer *; 
                            microphone *; 
                            midi *; 
                            payment *; 
                            picture-in-picture *; 
                            screen-wake-lock *; 
                            speaker *; 
                            sync-xhr *; 
                            usb *; 
                            web-share *; 
                            vibrate *; 
                            vr *"
                        className='animate__animated animate__fadeIn animate__slower' 
                        src={getSrcIframe(window.localStorage.getItem("kubboch-sto"))} 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            width: "100vw", 
                            height: "100vh", 
                            border: "none", 
                            margin: 0, 
                            padding: 0, 
                            overflow: "hidden !important", 
                            zIndex: 999999, 
                            visibility: visibility
                            
                         }}
                        >
                        </iframe>
                    </div>
                    </>
            }
        </>
    )
}
