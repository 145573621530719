import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

import LoadingView from '../loading/LoadingView';
import { LocalizeText } from '../../Globals';
import { VipsComposer } from '../../packets/VipsComposer';

import './Vips.scss'
import VipUserItemView from './VipUserItemView';

const VipsView = (props) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [loading, setLoading] = useState(true);
    const [vips, setVips] = useState<any[]>([]);

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        document.title = "VIP HABBET"
        sendMessage(JSON.stringify(new VipsComposer()))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "usersVips" && message.data !== null) {
                setVips(message.data.vips)
                setLoading(false);
            }
        }
    }, [lastMessage, setMessageHistory]);

    const getRandomVips = () => {
        const shuffledVips = vips.sort(() => 0.5 - Math.random()); 
        return shuffledVips.slice(0, 6);
    };

    const randomVips = getRandomVips(); 

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className="max-w-7xl mx-auto grid grid-cols-12 mt-5 container-dashboard animate__animated animate__fadeIn animate__slower gap-4">
                    <div className='col-span-12 flex flex-col gap-2'>
                        <div className="flex items-center gap-3 bg-primary rounded p-2">
                            VIP
                        </div>
                        <div className="flex flex-wrap gap-4">
                            {randomVips.map((x, index) => (
                                <VipUserItemView key={index} look={x.look} username={x.username} />
                            ))}
                        </div>
                    </div>

                    <div className='col-span-12 flex flex-col gap-2'>
                        <div className="flex items-center justify-center bg-primary rounded p-2">
                        Informazioni su Habbet VIP
                        </div>
                        <div className="bg-primary p-2 rounded flex justify-center gap-4">
                            <img className="img-fluid" src="https://i.imgur.com/0Lc5aoU.png" style={{ maxWidth: '100%', height: 'auto' }} />
                            <img className="img-fluid" src="https://i.imgur.com/DSuJ35T.png" style={{ maxWidth: '100%', height: 'auto' }} />
                            <img className="img-fluid" src="https://i.imgur.com/DqwgAET.png" style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default VipsView;
