export const timeSince = (date: number) => {
    var seconds = Math.floor((new Date().valueOf() - new Date(date * 1000).valueOf()) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) return Math.floor(interval) + " Anni fa";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " Mesi fa";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " Giorni fa";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " Ore fa";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " Minuti fa";

    return Math.floor(seconds) + " Secondi fa";
}

export const getImagerForTops = (look) => "url(https://nitro-imager.habbet.it/?figure="+look+"&headonly=0&head_direction=2&gesture=sml&action=wav)"; 
export const getImagerForTopsProfile = (look) => "url(https://nitro-imager.habbet.it/?figure="+look+"&headonly=1&head_direction=2&gesture=sml&action=wav)"; 
export const getImagerForProfile = (look) => "https://nitro-imager.habbet.it/?figure="+look+"&direction=4&head_direction=4&gesture=sml"; 
export const getNewsBackgroundCss = (url) => "url("+url+")"; 
export const getNewsBackgroundHref = (url) => url;
export const getImagerAuthorLook = (url) => "url(https://nitro-imager.habbet.it/?figure="+url+"&direction=2&head_direction=2&size=l&img_format=gif)";
export const getNewsImagerAuthorLook = (url) => "url(https://nitro-imager.habbet.it/?figure="+url+"&direction=2&head_direction=2&size=m&img_format=gif)";
export const getPreviewImage = (image) => "url("+image+")";
export const getReactionIdById = (id) => "reactionDisplay-"+id;
export const getBadgeUrl = (code) => "https://images.habbet.it/c_images/album1584/"+code+".gif";
export const getProfileUrl = (name) => "/profile/"+name;

export const getArticleRoute = (id: number, name: string) => "/article/"+id+"/"+name.replace(/ /g, "-");

export function LocalizeText(texts: {[key: string] : string}, text: string) : string
{
    if(texts === null) return "";
    
    for(const [key, value]  of Object.entries(texts)){
        if(key === text){
            return value;
        }
    }
    
    return text
}