import React from 'react'
import { getImagerForTops, getProfileUrl } from '../../Globals'
import { Link } from 'react-router-dom'

const CommunityTop = (props) => {
  return (
    <div className="flex justify-between items-center hover:-translate-y-1 transition-all  bg-primary p-3 rounded icon-keko-separator">
      <div className="flex items-center gap-2">
        <div className="!bg-center rounded-[50%] min-w-[64px] max-w-[64px] max-h-[64px] min-h-[64px] group overflow-hidden" style={{ background: "url(https://i.imgur.com/a4VVjmO.png)", boxShadow: "0 0 0 5px rgba(0, 0, 0, .20)" }}>
          <div className="!w-[64px] group-hover:-translate-y-2 transition-all h-[110px] -mt-2" style={{ backgroundImage: getImagerForTops(props.look) }}></div>
        </div>
        <p className='font-bold text-white'>{props.username}</p>
      </div>
      <div className="bg-secondary p-2 rounded hidden md:block">
        {props.amount} {props.topName}
      </div>
    </div>
  )
}

export default CommunityTop
