class DataTwo{
    background: any;
    stickers: any;
    widgets: any;
    notes: any

    constructor(background: any, stickers: any, widgets: any, notes: any){
        this.background = background;
        this.stickers = stickers;
        this.widgets = widgets;
        this.notes = notes;
    }
}

class Data{
    style: string;
    constructor(background: any, stickers: any, widgets: any, notes: any){
        this.style = JSON.stringify(new DataTwo(background, stickers, widgets, notes));
    }
}

export class UpdateProfileComposer{
    header: string;
    data: Data;

    constructor(background: any, stickers: any, widgets: any, notes: any){
        this.header = "updateProfile";
        this.data = new Data(background, stickers, widgets, notes);
    }
}