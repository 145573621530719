class Data{
    id: number;
    message: string;

    constructor(id: number, message: string){
        this.id = id;
        this.message = message;
    }
}

export class ArticleCommentComposer{
    header: string;
    data: Data;

    constructor(id: number, message: string){
        this.header = "newComment";
        this.data = new Data(id,message);
    }
}