import React, { useEffect, useState } from 'react'
import { NewUserBannerView } from './NewUserBannerView'
import PhotoHeaderView from './PhotoHeaderView'
import { Link } from 'react-router-dom'

interface BannerViewProps{
    account: any
}

export const BannerLoggedView = (props: BannerViewProps) => {
  const [photos, setPhotos] = useState<any[]>([]);

  return (
      <div className='h-80 relative flex items-center' style={{ background: "url(https://i.imgur.com/a4VVjmO.png)", backgroundPositionY: "-87px" }}>
          <div className="absolute jeBlackedHeader"></div>
          <div className="max-w-7xl w-full mx-auto flex flex-col md:flex-row items-center justify-between p-20 gap-4 relative">
                <div className="flex items-center gap-4">
                    <div className="w-32 h-32 hidden md:flex items-center justify-center bg-opacity-50 bg-black rounded-full" style={{boxShadow:"0 0 0 5px rgba(0, 0, 0, .20)"}}>
                        <img className='mt-20 -ml-1' src={`https://nitro-imager.habbet.it/?figure=${props.account.look}&gesture=sml&direction=3&size=l&action=wave&headonly=1`} />
                    </div>
                    <div className="flex flex-col gap-2 mt-4 text-center md:text-start">
                        <p className='text-2xl'>Ciao, <b>{props.account.username}</b></p>
                        <p>Motto: <b className='italic'>"{props.account.motto}"</b></p>
                        <div className="gap-4 relative top-1 hidden md:flex">
                            <div className="flex items-center">
                                <i className='icon header-credits'></i>
                                <div className="flex flex-col text-white font-bold">
                                    <span className='text-xl'>{props.account.credits}</span>
                                    <span className='text-xs'>Crediti</span>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <i className='icon header-diamonds'></i>
                                <div className="flex flex-col text-white font-bold">
                                    <span className='text-xl'>{props.account.diamonds}</span>
                                    <span className='text-xs'>Diamanti</span>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <i className='icon header-duckets'></i>
                                <div className="flex flex-col text-white font-bold">
                                    <span className='text-xl'>{props.account.currency}</span>
                                    <span className='text-xs'>HBT Punti</span>
                                </div>
                            <div className="flex items-center">
                                <i className='icon header-ruby'></i>
                                <div className="flex flex-col text-white font-bold">
                                    <span className='text-xl'>{props.account.duckets}</span>
                                    <span className='text-xs'>Ruby</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="/play" className="jeHeaderLoginButton px-10 rounded font-bold py-3">Gioca</Link>
          </div>
      </div>
  )
}
