import React, { useState } from 'react'
import { timeSince } from '../../../Globals';
import { Link } from 'react-router-dom';

const CatalogItemUserView = (props) => {
    const [onMouse, setOnMouse] = useState(false);

    return (
        <Link to={"/profile/" + props.username} style={{textDecoration: "none"}}>
            <div onMouseEnter={() => setOnMouse(true)} onMouseLeave={() => setOnMouse(false)} className="last-registers-item-bg dark-habbo-outline bg-dark">
                <div className='users-catalog-item'>
                    <div className='row' style={{height: "100%", width: "100%"}}>
                        <div className='col-6'>
                            <div style={{ width: "100%", height: "100%", backgroundPosition: "50% 20%", backgroundImage: `url(https://nitro-imager.habbet.it/?figure=${props.look}&direction=2&head_direction=2${onMouse ? "&action=wlk,wav,drk=1" : ""})` }}></div>
                        </div>
                        <div className='col-6 mt-1'>
                            <span className='text-white' style={{fontWeight: "bolder"}}>{props.username}</span>
                            <div className='text-white' style={{fontWeight: "bolder", fontSize: "small"}}>Hat: {props.quantity}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CatalogItemUserView