class Data{

}

export class NewsComposer{
    header: string;
    data: Data;

    constructor(){
        this.header = "news";
        this.data = new Data();
    }
}