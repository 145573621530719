import React, {useEffect} from 'react'
import "./Loading.scss";

const LoadingView = () => {
  useEffect(() => {
    
  }, [])

  return (
    <div className="loader" id="loadingScreen">
        <div className="spin flex items-center justify-center">
            <div className="spinner"></div>
            <img className='w-[29px] relative -left-5 -top-5 h-[33px]' src="https://habborator.org/archive/stickers/general/small/sticker_flames.gif" alt="sticker_catinabox" />
        </div>
    </div>
  )
}

export default LoadingView
