class Data{
    newId: number;
    commentId: number;

    constructor(newId: number, commentId: number){
        this.newId = newId;
        this.commentId = commentId;
    }
}

export class DeleteArticleCommentComposer{
    header: string;
    data: Data;
    
    constructor(newId: number, commentId: number){
        this.header = "removeComment";
        this.data = new Data(newId,commentId);
    }
}