import React from 'react'
import  "./Community.scss";
import { getImagerForTops, LocalizeText } from '../../Globals';

const CommunityPhoto = (props) => {
  const getPhotoCss = () => "url("+props.url+")";
  return (
    <div className="col-md-4 mt-4">
        <div className='community-photo w-100' style={{background: getPhotoCss(), backgroundSize: "cover"}}>
            <div className='photo-info text-white'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='photo-info-keko' style={{background: getImagerForTops(props.look), backgroundPositionY: "-16px"}}/>
                    </div>
                    <div className='col-md-10'><div className='photo-info-username pre-line mt-3'>{LocalizeText(props.texts, 'community.global.photos')} {props.username}</div></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommunityPhoto
