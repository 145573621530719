class Data{

}

export class TweetsComposer{
    header: string;
    data: Data;
    constructor(){
        this.header = "tweets";
        this.data = new Data();
    }
}