class Data{
    tweetId: number;

    constructor(tweetId: number){
        this.tweetId = tweetId;
    }
}

export class DeleteTweetComposer{
    header: string;
    data: Data;

    constructor(tweetId: number){
        this.header = "deleteTweet";
        this.data = new Data(tweetId);
    }
}