import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';

import LoadingView from '../loading/LoadingView';
import { CommunityComposer } from '../../packets/CommunityComposer';

import CommunityTop from './CommunityTop';
import CommunityRoomTop from './CommunityRoomTop';
import CommunityPhoto from './CommunityPhoto';
import CommunityGroupTop from './CommunityGroupTop';
import { LocalizeText } from '../../Globals';


const CommunityView = (props) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const navigate = useNavigate();
    const params = useParams();

    const [section, setSection] = useState("0");
    const [loading, setLoading] = useState(true);
    const [achievementScore, setAchievementScore] = useState<any>(null);
    const [RespectEarned, setRespectEarned] = useState<any>(null);
    const [RespectGiven, setRespectGiven] = useState<any>(null);
    const [AllTimeHotelPresence, setAllTimeHotelPresence] = useState<any>(null);
    const [RoomEntry, setRoomEntry] = useState<any>(null);
    const [RoomDecoFurniCount, setRoomDecoFurniCount] = useState<any>(null);
    const [BattleBallWinner, setBattleBallWinner] = useState<any>(null);
    const [FreezeWinner, setFreezeWinner] = useState<any>(null);
    const [FootballGoalScored, setFootballGoalScored] = useState<any>(null);
    const [roomsPopulars, setRoomsPopulars] = useState<any>(null);
    const [roomsScores, setRoomsScores] = useState<any>(null);
    const [kubbitos, setKubbitos] = useState<any>(null);
    const [diamonds, setDiamonds] = useState<any>(null);
    const [credits, setCredits] = useState<any>(null);
    const [duckets, setDuckets] = useState<any>(null);
    const [photos, setPhotos] = useState<any>(null);
    const [gamePoints, setGamePoints] = useState<any>(null);
    const [groups, setGroups] = useState<any>(null);

    const selectSection = (section) => {
        if (section === null) setSection("0");
        else setSection(section);
    }


    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        document.title = "Community | Habbet"

        if (params === null || params.type === null) navigate("/me", { replace: true });
        selectSection(params.type);
        sendMessage(JSON.stringify(new CommunityComposer()))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "topsStats" && message.data !== null) {
                setAchievementScore(JSON.parse(message.data.AchievementScore));
                setRespectEarned(JSON.parse(message.data.RespectEarned));
                setRespectGiven(JSON.parse(message.data.RespectGiven));
                setAllTimeHotelPresence(JSON.parse(message.data.AllTimeHotelPresence));
                setRoomEntry(JSON.parse(message.data.RoomEntry));
                setRoomDecoFurniCount(JSON.parse(message.data.RoomDecoFurniCount));
                setBattleBallWinner(JSON.parse(message.data.BattleBallWinner));
                setFreezeWinner(JSON.parse(message.data.FreezeWinner));
                setFootballGoalScored(JSON.parse(message.data.FootballGoalScored));
                setPhotos(JSON.parse(message.data.photos));
                setRoomsPopulars(JSON.parse(message.data.roomsPopulars));
                setRoomsScores(JSON.parse(message.data.roomsScores));
                setKubbitos(JSON.parse(message.data.currency));
                setDiamonds(JSON.parse(message.data.diamonds));
                setCredits(JSON.parse(message.data.credits));
                setDuckets(JSON.parse(message.data.duckets));
                setGamePoints(JSON.parse(message.data.points));
                setGroups(JSON.parse(message.data.groups));

                setLoading(false);
            }
        }
    }, [lastMessage, setMessageHistory]);

    const changeSection = (section) => {
        setLoading(true);
        navigate("/community/" + section, { replace: true });
        setSection(section);
        setLoading(false);
    }

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className="max-w-7xl mx-auto gap-4 w-full grid grid-cols-12 container-dashboard animate__animated animate__fadeIn animate__slower mt-5">
                    {section === "0" &&
                        <>
                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.groupscore")}
                                    </div>
                                    <div className="flex flex-col i"></div>
                                    {groups.length != 0 && groups.slice(0, 3).map((group) =>
                                        <CommunityGroupTop key={Math.random()} name={group.name} memberCount={group.memberCount} url={group.badge} texts={props.texts} />
                                    )}
                                </div>
                                

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.currency")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {kubbitos.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, "global.currency")} amount={keko.amount} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.diamonds")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {diamonds.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, "global.diamonds")} amount={keko.amount} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.credits")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {credits.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, "global.credits")} amount={keko.amount} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.duckets")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {duckets.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, "global.duckets")} amount={keko.amount} />
                                    )}
                                </div>

                                



                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.score")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {achievementScore.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, "community.global.score")} amount={keko.stats} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.respects")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {RespectEarned.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, "community.global.respects")} amount={keko.stats} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.respectsgive")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {RespectGiven.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, "community.global.respectsgive")} amount={keko.stats} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.online")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {AllTimeHotelPresence.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.online')} amount={keko.stats} />
                                    )}
                                </div>

                                

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.roomsvisits")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {RoomEntry.slice(0, 3).map((keko) =>
                                        <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.roomsvisits')} amount={keko.stats} />
                                    )}
                                </div>

                                <div className="flex flex-col col-span-12 md:col-span-6">
                                    <div className="p-4 bg-primary rounded">
                                        {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "i più popolari della stanza")}
                                    </div>
                                    <div className="flex flex-col "></div>
                                    {roomsPopulars.length !== 0 ? (
                                        <>
                                            {roomsPopulars.slice(0, 3).map((room) =>
                                                <CommunityRoomTop key={Math.random()} roomName={room.roomName} userCount={room.userCount} userMax={room.userMax} roomScore={room.roomScore} texts={props.texts} />
                                            )}
                                        </>
                                    ) : <div className='bg-primary p-2 mt-1 rounded text-center text-white'>Nessuna stanza visitata</div>}
                                </div>



                                




      
                        </>
                    }
                    {section === "1" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top3") + LocalizeText(props.texts, "community.top.score")}
                                </div>
                                <div className="flex flex-col "></div>
                                {achievementScore.slice(0, 3).map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, "community.global.score")} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "2" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.respects")}
                                </div>
                                <div className="flex flex-col "></div>
                                {RespectEarned.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.respects')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "3" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.respectsgive")}
                                </div>
                                <div className="flex flex-col "></div>
                                {RespectGiven.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.respectsgive')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "4" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.online")}
                                </div>
                                <div className="flex flex-col "></div>
                                {AllTimeHotelPresence.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.online')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "5" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.roomsvisits")}
                                </div>
                                <div className="flex flex-col "></div>
                                {RoomEntry.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.roomsvisits')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "7" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.banzaiwinners")}
                                </div>
                                <div className="flex flex-col "></div>
                                {BattleBallWinner.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.banzaiwinners')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "8" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.freezewinners")}
                                </div>
                                <div className="flex flex-col "></div>
                                {FreezeWinner.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.freezewinners')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "9" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.golscore")}
                                </div>
                                <div className="flex flex-col "></div>
                                {FootballGoalScored.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.account.look} username={keko.account.username} topName={LocalizeText(props.texts, 'community.global.golscore')} amount={keko.stats} />
                                )}
                            </div>
                        </>
                    }
                    {section === "13" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.currency")}
                                </div>
                                <div className="flex flex-col "></div>
                                {kubbitos.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, 'global.currency')} amount={keko.amount} />
                                )}
                            </div>
                        </>
                    }
                    {section === "14" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.diamonds")}
                                </div>
                                <div className="flex flex-col "></div>
                                {diamonds.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, 'global.diamonds')} amount={keko.amount} />
                                )}
                            </div>
                        </>
                    }
                    {section === "15" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.credits")}
                                </div>
                                <div className="flex flex-col "></div>
                                {credits.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, 'global.credits')} amount={keko.amount} />
                                )}
                            </div>
                        </>
                    }
                    {section === "16" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.duckets")}
                                </div>
                                <div className="flex flex-col "></div>
                                {duckets.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, 'global.duckets')} amount={keko.amount} />
                                )}
                            </div>
                        </>
                    }
                    {section === "17" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.gamepoints")}
                                </div>
                                <div className="flex flex-col "></div>
                                {gamePoints.map((keko) =>
                                    <CommunityTop key={Math.random()} look={keko.look} username={keko.username} topName={LocalizeText(props.texts, 'community.global.gamepoints')} amount={keko.amount} />
                                )}
                            </div>
                        </>
                    }
                    {section === "10" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "i più popolari della stanza")}
                                </div>
                                <div className="flex flex-col "></div>
                                {roomsPopulars.length !== 0 ? (
                                    <>
                                        {roomsPopulars.map((room) =>
                                            <CommunityRoomTop key={Math.random()} roomName={room.roomName} userCount={room.userCount} userMax={room.userMax} roomScore={room.roomScore} texts={props.texts} />
                                        )}
                                    </>
                                ) : <div className='bg-primary p-2 mt-1 rounded text-center text-white'>Nessuna stanza visitata</div>}
                            </div>
                        </>
                    }
                    {section === "11" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.roomsscore")}
                                </div>
                                <div className="flex flex-col "></div>
                                {roomsScores.length !== 0 ? (
                                    <>
                                        {roomsScores.map((room) =>
                                            <CommunityRoomTop key={Math.random()} roomName={room.roomName} userCount={room.userCount} userMax={room.userMax} roomScore={room.roomScore} texts={props.texts} />
                                        )}
                                    </>
                                ) : <div className='bg-primary p-2 mt-1 rounded text-center text-white'>Keine besuchte Räume</div>}
                            </div>
                        </>
                    }
                    {section === "12" &&
                        <>
                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    Photos
                                </div>
                                <div className="flex flex-col "></div>
                                {photos.map((photo) =>
                                    <CommunityPhoto key={Math.random()} url={photo.url} look={photo.account.look} username={photo.account.username} texts={props.texts} />
                                )}
                            </div>
                        </>
                    }
                    {section === "18" &&
                        <>

                            <div className="flex flex-col col-span-12">
                                <div className="p-4 bg-primary rounded">
                                    {LocalizeText(props.texts, "community.top") + LocalizeText(props.texts, "community.top.groupscore")}
                                </div>
                                <div className="flex flex-col "></div>
                                {groups.length != 0 && groups.map((group) =>
                                    <CommunityGroupTop key={Math.random()} name={group.name} memberCount={group.memberCount} url={group.badge} texts={props.texts} />
                                )}
                            </div>
                        </>
                    }


                    </div>

            )}
        </>
    )
}

export default CommunityView
