import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';

import "./Article.scss"


import { timeSince, getImagerForTops, getNewsBackgroundHref, getArticleRoute, getNewsImagerAuthorLook, getProfileUrl, LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { ArticleComposer } from '../../packets/ArticleComposer';
import { NewsComposer } from '../../packets/NewsComposer';
import { ArticleCommentComposer } from '../../packets/ArticleCommentComposer';
import { DeleteArticleCommentComposer } from '../../packets/DeleteArticleCommentComposer';
import { NotificationManager } from 'react-notifications';

const ArticleView = (props, { match }) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://cms.habbet.it:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const navigate = useNavigate();
  const params = useParams();

  const [article, setArticle] = useState<any>({});
  const [news, setNews] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");

  const getArticleIdByParam = (param) => {
    if (param === null) return 0;
    else return param;
  }

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    if (params === null || params.id === null) navigate("/articles", { replace: true });
    document.title = params.name?.replace("-", " ") + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new ArticleComposer(getArticleIdByParam(params.id))))
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "newdata" && message.data !== null) {
        if (message.data.new === null) navigate("/articles", { replace: true });
        setArticle(JSON.parse(message.data.new));
        setComments(JSON.parse(message.data.comments));
      }
      if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
      if (message.header === "CommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Error") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else if (message.data.data === "Comentario no valido") NotificationManager.error(LocalizeText(props.texts, 'article.comment.invalid'), "Error")
        else if (message.data.data === "No intentes hacer publicidad!") NotificationManager.error(LocalizeText(props.texts, 'article.comment.publi'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.success'), "OK")
      }
      if (message.header === "removeCommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.remove'), "OK")
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (news.length !== 0 && article !== null) {
      setLoading(false);
    }
  }, [article, news])

  const changeNotice = (id) => {
    setArticle(null);
    setLoading(true);
    sendMessage(JSON.stringify(new ArticleComposer(id)))
  }

  const createComment = () => {
    if (comment === "") {
      NotificationManager.error(LocalizeText(props.texts, 'article.comment.notext'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new ArticleCommentComposer(article.id, comment)));
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  const deleteComment = (id) => {
    sendMessage(JSON.stringify(new DeleteArticleCommentComposer(article.id, id)))
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  return (
    <>
      {loading ? <LoadingView /> : (
        <>
          {article !== null &&
            <div className='max-w-7xl w-full gap-4 mx-auto grid grid-cols-12 container-dashboard animate__animated animate__fadeIn animate__slower mt-5'>
              <div className="col-span-12 md:col-span-8">
                <div className="w-full bg-primary">
                  <div className="flex flex-col relative">
                    <img src={article.header} className="w-full" />
                    <div className="absolute jeBlackedHeaderReverse"></div>
                    <div className="absolute p-4">
                      <p className='text-3xl'>{article.title}</p>
                      <p>{article.shortStory}</p>
                      <p className='text-white text-opacity-50 italic'>{LocalizeText(props.texts, 'article.post.date')} {timeSince(article.date)}</p>
                      <div className="max-w-fit flex p-2 h-14 overflow-hidden mt-4 rounded bg-primary bg-opacity-50">
                        <div style={{ background: getNewsImagerAuthorLook(article.author.look) }} className="w-[64px] relative top-6 -left-2 article-author-look" />
                        <div className="flex flex-col relative">
                          <p className='text-lg'>{article.author.username}</p>
                          <p className='text-sm italic -mt-1'>{article.author.motto}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ overflowX: "auto" }}>
                    <div id="articleContent" style={{ whiteSpace: "pre-wrap" }} className="text-white p-3" dangerouslySetInnerHTML={{ __html: article.fullStory }} />
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <div className="p-2 bg-primary rounded">{LocalizeText(props.texts, 'article.comments.header')}</div>
                  <div className="p-2 bg-primary rounded flex items-center flex-col">
                    <div className="bg-primary max-h-80 overflow-y-auto w-full">
                      {comments.length !== 0 ? (
                        <>
                          {comments.map((comment) =>
                            <div key={comment.id} className="row mb-4">
                              <div className="flex flex-col items-center gap-3 mt-1">
                                <div className="!bg-center rounded-[50%] min-w-[64px] max-w-[64px] max-h-[64px] min-h-[64px] group overflow-hidden" style={{ background: "url(https://i.imgur.com/a4VVjmO.png)", boxShadow: "0 0 0 5px rgba(0, 0, 0, .20)" }}>
                                  <div className="!w-[64px] group-hover:-translate-y-2 transition-all h-[110px] -mt-2" style={{ backgroundImage: getImagerForTops(comment.account.look) }}></div>
                                </div>
                                {props.account !== null && comment.account.id === props.account.id ? (
                                  <span onClick={() => deleteComment(comment.id)} className="badge bg-danger me-pointer text-sm">Löschen</span>
                                ) : (
                                  <span className="badge bg-dark">{comment.account.username}</span>
                                )}
                                <div className="flex w-full relative flex-col gap-1">
                                  <div className="p-2 rounded bg-secondary max-w-fit mx-auto">
                                    {comment.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : <div className="alert bg-danger text-white">{LocalizeText(props.texts, 'article.comments.nofound')}</div>}
                    </div>
                    {props.account !== null &&
                      <div className="card-body text-white mt-2">
                        <textarea onChange={(e) => setComment(e.target.value)} placeholder='Leave a comment...' className='jeInput !rounded'></textarea>
                        <button onClick={() => createComment()} className='btn btn-success success-habbo btn-lg w-100 mt-2 jeHeaderLoginButton w-full rounded p-2 font-bold'>{LocalizeText(props.texts, 'article.comments.publish')}</button>
                      </div>
                    }
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="p-2 bg-primary rounded">{LocalizeText(props.texts, 'article.last.new')}</div>
                  {news[0] != null &&
                    <div className="p-2 bg-primary group rounded flex gap-2 items-center">
                      <img className='notices-thumbnail !-mt-0 rounded  group-hover:translate-x-3 transition-all duration-200 ' src={getNewsBackgroundHref(news[0].header)} />
                      <div className="flex flex-col  group-hover:translate-x-3 transition-all duration-200 ">
                        <p className='text-lg'> <Link onClick={() => changeNotice(news[0].id)} to={getArticleRoute(news[0].id, news[0].title)} style={{ textDecoration: "none" }} className="text-white">{news[0].title}</Link></p>
                        <p className='text-sm'>{news[0].shortStory}</p>
                        <p className='text-xs italic'><i>{LocalizeText(props.texts, 'article.post.date')} {timeSince(news[0].date)}</i></p>
                      </div>
                    </div>
                  }
                  {news[1] != null &&
                    <div className="p-2 bg-primary group rounded flex gap-2 items-center">
                      <img className='notices-thumbnail !-mt-0 rounded  group-hover:translate-x-3 transition-all duration-200 ' src={getNewsBackgroundHref(news[1].header)} />
                      <div className="flex flex-col  group-hover:translate-x-3 transition-all duration-200 ">
                        <p className='text-lg'> <Link onClick={() => changeNotice(news[1].id)} to={getArticleRoute(news[1].id, news[1].title)} style={{ textDecoration: "none" }} className="text-white">{news[1].title}</Link></p>
                        <p className='text-sm'>{news[1].shortStory}</p>
                        <p className='text-xs italic'><i>{LocalizeText(props.texts, 'article.post.date')} {timeSince(news[1].date)}</i></p>
                      </div>
                    </div>
                  }
                  {news[2] != null &&
                    <div className="p-2 bg-primary group rounded flex gap-2 items-center">
                      <img className='notices-thumbnail !-mt-0 rounded  group-hover:translate-x-3 transition-all duration-200 ' src={getNewsBackgroundHref(news[0].header)} />
                      <div className="flex flex-col  group-hover:translate-x-3 transition-all duration-200 ">
                        <p className='text-lg'> <Link onClick={() => changeNotice(news[0].id)} to={getArticleRoute(news[2].id, news[2].title)} style={{ textDecoration: "none" }} className="text-white">{news[2].title}</Link></p>
                        <p className='text-sm'>{news[2].shortStory}</p>
                        <p className='text-xs italic'><i>{LocalizeText(props.texts, 'article.post.date')} {timeSince(news[2].date)}</i></p>
                      </div>
                    </div>
                  }
                  {news[3] != null &&
                    <div className="p-2 bg-primary group rounded flex gap-2 items-center">
                      <img className='notices-thumbnail !-mt-0 rounded  group-hover:translate-x-3 transition-all duration-200 ' src={getNewsBackgroundHref(news[3].header)} />
                      <div className="flex flex-col  group-hover:translate-x-3 transition-all duration-200 ">
                        <p className='text-lg'> <Link onClick={() => changeNotice(news[3].id)} to={getArticleRoute(news[0].id, news[0].title)} style={{ textDecoration: "none" }} className="text-white">{news[3].title}</Link></p>
                        <p className='text-sm'>{news[3].shortStory}</p>
                        <p className='text-xs italic'><i>{LocalizeText(props.texts, 'article.post.date')} {timeSince(news[3].date)}</i></p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default ArticleView
